import React from "react";

interface IconHashLineProps {
  color?: string;
  width?: string | number;
  height?: string | number;
  title?: string;
}

const IconHashLine: React.FC<IconHashLineProps> = ({
  color = "currentColor",
  width = 24,
  height = 24,
  title,
}) => (
  <svg
    role="img"
    aria-hidden={!title}
    aria-label={title}
    viewBox="0 0 24 24"
    width={width}
    height={height}
  >
    <path
      d="M8 5h1.5v4h3V5H14v4h3v1.5h-3v3h3V15h-3v4h-1.5v-4h-3v4H8v-4H5v-1.5h3v-3H5V9.5h3V5zm1.5 6v3h3v-3h-3z"
      fill={color}
    />
  </svg>
);

export default IconHashLine;
