import {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Unsubscribe,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { PRE_FETCH_SIZE } from "../../Constants/constants";
import { fetchPageData } from "../../api/retriever";
import { ParentDocTypeEnum } from "../../api/retriever.i";
import { fetchSuggestions } from "../../api/sequalizer";
import { ChartConfig } from "../../components/Charts/chart.i";
import { createHttpsCallable, getFirebaseFunctionsInstance } from "../../helpers/firebaseHelpers";
import { withSentry } from "../../helpers/wrapper";
import { Analytics } from "../../services/analytics/Analytics";
import {
  addToChatHistory,
  db,
  getSourceType,
  incrementUsageMetrics,
  updateTitleInThreadRoutine,
} from "../../services/firebase";
import { reportNegativeFeedback } from "../../services/webserver/feedback";
import { useAlert } from "../alert";
import { useAuth } from "../auth";
import { DataMap, FirebaseTimestamp, Pin } from "../pinboard/pinboard.i";
import { useUI } from "../ui";
import { ChatContextI, Result, ServerResult, ThreadMap, ThreadSummary } from "./chat.i";

const initialState: ChatContextI = {
  currentResult: null,
  setCurrentResult: async (result: Result | null) => {},
  searchHistory: [],
  setSearchHistory: async () => {},
  allResults: [],
  setAllResults: async () => {},
  answer: [],
  setAnswer: async () => {},
  threads: [],
  setThreads: async () => {},
  currentThread: "",
  setCurrentThread: () => {},
  fetchNextHistory: async () => {},
  chatCount: 0,
  deleteThread: async (documentId: string) => {},
  selectThread: async () => {},
  chatHistoryIds: [],
  setChatHistoryIds: async () => {},
  newChat: async () => {},
  dynamicCache: [],
  setDynamicCache: async () => {},
  updateSatisfaction: async () => {},
  updateIndividualSatisfaction: async () => {},
  updateTitle: async () => {},
  searchQuery: "",
  setSearchQuery: async () => {},
  searchResults: [],
  setSearchResults: async () => {},
  fetchRelevantChats: async () => {},
  loadingSearchResults: false,
  setLoadingSearchResults: async () => {},
  filteredThreads: [],
  selectResult: async () => {},
  loadingThread: false,
  setLoadingThread: async () => {},
  dataMap: {},
  setDataMap: async () => {},
  navigateToChatFromPin: async () => {},
  fetchSummaryFromPin: async (pin: Pin) => "",
  updateSqlInFirestore: async (sql: string) => {},
  updateChartConfigInFirestore: async (chartConfigurations: ChartConfig) => {},
  preFetchFirstPageData: async (result: Result) => {},
  fetchingSuggestions: false,
  loading: false,
  setLoading: async () => {},
  deleteChatFromThread: async () => {},
  updateResultsFields: (updates: Partial<Result>) => {},
  streamLoading: false,
  setStreamLoading: async () => {},
};
const ChatDataContext = createContext(initialState);

export function useChatData() {
  return useContext(ChatDataContext);
}

export const ChatDataProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { _ } = useLingui();
  const [currentResult, setCurrentResult] = useState<Result | null>(null);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [chatCount, setChatCount] = useState(0);
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [allResults, setAllResults] = useState<Result[]>([]);
  const [answer, setAnswer] = useState<string[]>([]);
  const [threads, setThreads] = useState<ThreadSummary[]>([]);
  const [currentThread, setCurrentThread] = useState<string>(uuidv4());
  const [chatHistoryIds, setChatHistoryIds] = useState<string[]>([]);
  const [dynamicCache, setDynamicCache] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [chatThreadMap, setChatThreadMap] = useState<ThreadMap[]>([]);
  const [filteredThreads, setFilteredThreads] = useState<ThreadSummary[]>([]);
  const [loadingThread, setLoadingThread] = useState(false);
  const [dataMap, setDataMap] = useState<DataMap>({});
  const [fetchingSuggestions, setFetchingSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [streamLoading, setStreamLoading] = useState(false);

  const { authUser, userDocument } = useAuth();
  const { showAlert } = useAlert();
  const { model } = useUI();

  const pageSize = 50;
  const newChat = () => {
    clearState();
    setCurrentThread(uuidv4());
  };

  const firebaseTimestampToLuxon = (firebaseTimestamp: FirebaseTimestamp) => {
    const asNumbers = firebaseTimestamp.seconds * 1000 + firebaseTimestamp.nanoseconds / 1000000;

    return DateTime.fromMillis(asNumbers);
  };

  useEffect(() => {
    if (
      !currentResult ||
      currentResult.type !== "PREDICTION" ||
      ["success", "error"].includes(currentResult.status)
    ) {
      return; // Exit if no need to set up a listener
    }

    async function setupDocListener() {
      const docRef = doc(
        db,
        "organizations",
        userDocument?.organization,
        "threads",
        currentThread,
        "chats",
        currentResult.id
      );

      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        await addToChatHistory(
          threads,
          currentThread,
          userDocument.organization,
          authUser.uid,
          searchHistory[searchHistory.length - 1],
          allResults[allResults.length - 1],
          answer[answer.length - 1]
        );
      }

      // Directly declare unsubscribe within the useEffect hook
      const unsubscribe = onSnapshot(docRef, async (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          const activeThread = threads.find((thread) => thread.id === currentThread);
          if (activeThread.title === "New Chat")
            await updateTitleInThreadRoutine(userDocument.organization, currentThread);

          if (["success", "error"].includes(data.status)) {
            data.timestamp = firebaseTimestampToLuxon(data.timestamp);
            setCurrentResult((prev) => ({ ...prev, ...data }));

            // Assuming there's a method like setAllResults to update context
            setAllResults((prevResults) =>
              prevResults.map((result) =>
                result.id === currentResult.id ? { ...result, ...data } : result
              )
            );

            setAnswer(
              answer.map((item, index) =>
                index === allResults.findIndex((elem) => elem.id === currentResult.id)
                  ? data.answer
                  : item
              )
            );

            unsubscribe();
          }
        } else {
          console.error("Document does not exist.");
        }
      });

      // Return a cleanup function directly from useEffect
      return () => unsubscribe();
    }

    setupDocListener();
  }, [currentResult, userDocument]);

  useEffect(() => {
    if (userDocument && userDocument.organization && model) {
      const chatThreadMappingRef = collection(
        db,
        "organizations",
        userDocument.organization,
        "chat_thread_mapping"
      );

      const q = query(chatThreadMappingRef, where("created_by", "==", userDocument.id));

      const unsubscribe = onSnapshot(q, (snapshot: QuerySnapshot<DocumentData>): void => {
        const updatedChatThreadMap = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as Omit<ThreadMap, "id">),
        }));

        setChatThreadMap(updatedChatThreadMap);
        fetchDynamicCache(updatedChatThreadMap?.map((item) => item.query), model);
      });

      return () => unsubscribe();
    }
  }, [userDocument, model]);

  const fetchThreads = () => {
    try {
      if (!userDocument || !userDocument.organization) throw new Error("User document not found");

      const org = userDocument.organization;
      const threadsCollectionRef = collection(db, "organizations", org, "threads");

      // Added a where clause to filter documents based on created_by field
      const threadsQuery = query(
        threadsCollectionRef,
        where("created_by", "==", userDocument.id),
        orderBy("created_at", "desc")
      );

      // Using onSnapshot for real-time updates
      const unsubscribe = onSnapshot(threadsQuery, (threadsSnapshot) => {
        let fetchedThreads: ThreadSummary[] = [];
        threadsSnapshot.forEach((doc) => {
          fetchedThreads.push(doc.data() as ThreadSummary);
        });
        setThreads(fetchedThreads);
      });

      // Return the unsubscribe function to allow for cleanup
      return unsubscribe;
    } catch (e) {
      console.error("Error fetch threads:", e);
    }
  };

  const deleteThread = withSentry(async (documentId) => {
    try {
      if (userDocument && userDocument.organization) {
        const org = userDocument.organization;
        const threadRef = doc(db, "organizations", org, "threads", documentId);
        await deleteDoc(threadRef);

        //Delete all the notifications for the thread
        const notificationsRef = collection(db, "organizations", org, "notifications");
        const notificationQuery = query(notificationsRef, where("thread_id", "==", documentId));
        const querySnapshot = await getDocs(notificationQuery);
        querySnapshot.forEach((doc) => {
          deleteDoc(doc.ref);
        });

        if (documentId === currentThread) {
          setCurrentThread(uuidv4());
          clearState();
        }
      }
    } catch (error) {
      console.error("Failed to delete thread:", error);
      throw error;
    }
  });

  const clearState = () => {
    setCurrentResult(null);
    setSearchHistory([]);
    setAllResults([]);
    setAnswer([]);
    setChatHistoryIds([]);
    setChatCount(0);
  };

  const populateData = (data: ServerResult) => {
    if (data.sql === "demo question" || data.sql === "demo_question") {
      return JSON.parse((data as any).result);
    } else if (data.type === "PREDICTION") {
      return {
        id: data.docId,
        parentDocId: data.parentDocId,
        parentDocType: data.parentDocType,
        query: data.query,
        visualisation: data?.visualisation,
        title: data.title,
        satisfied: data.satisfied,
        error: data.error,
        sql: data.sql ?? data.prediction_sql,
        timestamp: firebaseTimestampToLuxon(data.timestamp),
        originalSql: data.originalSql ?? data.sql ?? data.prediction_sql,
        status: data.status ?? "success",
        type: data.type ?? "QUERY",
        follow_up_prompts: data.follow_up_prompts || [],
        latency: data.latency ?? 0,
        chartConfig: data.chart_config,
      } as Result;
    } else {
      return {
        id: data.docId,
        parentDocId: data.parentDocId,
        parentDocType: data.parentDocType,
        query: data.query,
        visualisation: data?.chart_config?.suggestion,
        title: data.title,
        satisfied: data.satisfied,
        error: data.error,
        sql: data.sql ?? data.prediction_sql,
        timestamp: firebaseTimestampToLuxon(data.timestamp),
        originalSql: data.originalSql ?? data.sql ?? data.prediction_sql,
        status: data.status ?? "success",
        type: data.type ?? "QUERY",
        follow_up_prompts: data.follow_up_prompts || [],
        latency: data.latency ?? 0,
        chartConfig: data.chart_config,
      } as Result;
    }
  };

  const selectThread = withSentry(
    async (threadId: string, setLatestResult: string | null = null) => {
      clearState();
      setCurrentThread(threadId); // Set the current thread ID
      setChatCount(threads.filter((thread) => thread.id === threadId)[0].chatCount);
      setLoadingThread(true);

      try {
        if (!userDocument || !userDocument.organization) throw new Error("User document not found");

        const chatsRef = collection(
          db,
          "organizations",
          userDocument.organization,
          "threads",
          threadId,
          "chats"
        );

        const firstChatsQuery = query(chatsRef, orderBy("timestamp", "desc"), limit(pageSize));
        const chatSnapshot = await getDocs(firstChatsQuery);
        const lastVisible = chatSnapshot.docs[chatSnapshot.docs.length - 1];
        setLastVisible(lastVisible);

        const chats = {
          answer: [] as string[],
          question: [] as string[],
          result: [] as Result[],
          chatHistoryIds: [] as string[],
        };
        chatSnapshot.forEach(async (doc) => {
          const data = {
            ...doc.data(),
            parentDocId: threadId,
            parentDocType: ParentDocTypeEnum.THREAD,
          } as unknown as ServerResult;

          chats.answer.push(data.answer);
          chats.question.push(data.query);
          chats.result.push(populateData(data));
          chats.chatHistoryIds.push(data.docId);
        });

        const { answer, question, result, chatHistoryIds } = chats;
        if (result.length !== 0) {
          if (setLatestResult) {
            const res = result.find((item) => item.id === setLatestResult) || result[0];
            await selectResult(res);
          } else {
            setCurrentResult(result[0]);
            if (result.length > 1) preFetchFirstPageData(result[1]);
          }

          setAllResults(result.reverse());
        }
        if (question.length !== 0) setSearchHistory(question.reverse());
        if (answer.length !== 0) setAnswer(answer.reverse());
        if (chatHistoryIds.length !== 0) setChatHistoryIds(chatHistoryIds.reverse());
      } catch (error) {
        console.error("Error fetching chats for thread:", error);
        throw error;
      } finally {
        setLoadingThread(false);
      }
    }
  );

  const navigateToChatFromPin = withSentry(async (chatId: string) => {
    const { id, thread_id } = chatThreadMap.find((item) => item.id === chatId)!;

    await selectThread(thread_id, id);
  });

  const fetchSummaryFromPin = withSentry(async (pin: Pin) => {
    const { id, thread_id } = chatThreadMap.find((item) => item.id === pin.id)!;
    try {
      if (!userDocument || !userDocument.organization) throw new Error("User document not found");

      const chatRef = doc(
        db,
        "organizations",
        userDocument.organization,
        "threads",
        thread_id,
        "chats",
        id
      );
      const chatSnapshot = await getDoc(chatRef);
      const data = chatSnapshot.data();
      return data?.answer;
    } catch (e) {
      console.error("Error fetching chat summary", e);
      throw e;
    }
  });

  const preFetchFirstPageData = withSentry(async (result) => {
    try {
      if (dataMap[result.id]) {
        return;
      }

      if (
        (result.sql && result.sql !== "demo_question") ||
        (result.type === "PREDICTION" && result.status === "success")
      ) {
        if (!userDocument || !userDocument.organization) throw new Error("User document not found");
        const prefetchPageSize = PRE_FETCH_SIZE;
        const { data, headers } = await fetchPageData({
          page_size: prefetchPageSize,
          page_number: 0,
          doc_id: result.id,
          parent_doc_id: currentThread,
          parent_doc_type: ParentDocTypeEnum.THREAD,
          retrieval_type: result.type === "PREDICTION" ? "prediction" : "query",
          user_defined_query: result.originalSql && result.sql !== result.originalSql,
        });
        const totalRowCount = data.totalRows;
        const hyperlinkColumns = data.hyperlink_columns;
        const dataTypes = data.data_types;
        const formattedData = data.rows?.reduce((acc, row, index) => {
          return { [index]: row, ...acc };
        }, {});

        setDataMap((prev) => {
          return {
            ...prev,
            [result.id]: {
              rows: {
                ...prev[result.id]?.rows,
                ...formattedData,
              },
              pageInfo: {
                totalRowCount: totalRowCount,
                headers: headers,
                hyperlinkColumns: hyperlinkColumns,
                dataTypes: dataTypes,
              },
            },
          };
        });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  });

  const fetchNextHistory = withSentry(async () => {
    if (!lastVisible) return;

    try {
      if (!userDocument || !userDocument.organization) throw new Error("User document not found");

      const chatsRef = collection(
        db,
        "organizations",
        userDocument.organization,
        "threads",
        currentThread,
        "chats"
      );

      const chatsQuery = query(
        chatsRef,
        orderBy("timestamp", "desc"),
        startAfter(lastVisible),
        limit(pageSize)
      );

      const chatSnapshot = await getDocs(chatsQuery);

      setLastVisible(chatSnapshot.docs[chatSnapshot.docs.length - 1]);
      const chats = {
        answers: [] as string[],
        question: [] as string[],
        result: [] as Result[],
        chatHistoryIds: [] as string[],
      };

      chatSnapshot.forEach((doc) => {
        const data = {
          ...doc.data(),
          parent_doc_id: currentThread,
          parent_doc_type: ParentDocTypeEnum.THREAD,
        } as unknown as ServerResult;

        chats.answers.push(data.answer);
        chats.question.push(data.query);
        chats.result.push(populateData(data));
        chats.chatHistoryIds.push(data.docId);
      });

      const { answers, question, result, chatHistoryIds } = chats;
      if (result.length !== 0) {
        setAllResults([...result.reverse(), ...allResults]);
      }
      if (question.length !== 0) setSearchHistory([...question.reverse(), ...searchHistory]);
      if (answer.length !== 0) setAnswer([...answers.reverse(), ...answer]);
      if (chatHistoryIds.length !== 0)
        setChatHistoryIds([...chatHistoryIds.reverse(), ...chatHistoryIds]);
    } catch (error) {
      console.error("Error fetching chats for thread:", error);
      throw error;
    }
  });

  /**
   * Fetches valid documents for the user's organization from the questions collection in Firebase
   */
  const fetchDynamicCache = withSentry(async (previousQuestions = [], model) => {
    try {
      setFetchingSuggestions(true);
      const sourceTypes = await getSourceType(userDocument?.organization);

      const query_type = model === "Predict" ? "prediction" : "query";
      const suggestedQuestions = await fetchSuggestions(
        userDocument?.organization,
        previousQuestions,
        sourceTypes,
        query_type
      );
      if (suggestedQuestions) {
        setDynamicCache(suggestedQuestions);
      }
    } catch (e) {
      throw e;
    } finally {
      setFetchingSuggestions(false);
    }
  });

  const updateTitle = withSentry(async (title: string) => {
    try {
      if (!userDocument || !userDocument.organization) throw new Error("User document not found");

      const chatHistoryCollectionRef = collection(
        db,
        "organizations",
        userDocument.organization,
        "threads",
        currentThread,
        "chats"
      );
      const updatedData = {
        title: title,
      };

      const docToUpdate = doc(chatHistoryCollectionRef, currentResult!.id);
      await updateDoc(docToUpdate, updatedData);
      updateResultsFields(updatedData);
      showAlert({ variant: "success", message: _(msg`Title updated successfully!`) });
    } catch (e) {
      console.error("Error updating chat record: ", e);
      showAlert({
        variant: "error",
        message: _(msg`Failed to update title. Please try again later.`),
      });
      throw e;
    }
  });

  const updateSqlInFirestore = withSentry(async (sql: string) => {
    try {
      if (!userDocument || !userDocument.organization) throw new Error("User document not found");

      const chatHistoryCollectionRef = collection(
        db,
        "organizations",
        userDocument.organization,
        "threads",
        currentThread,
        "chats"
      );

      const docToUpdate = doc(chatHistoryCollectionRef, currentResult?.id);
      const chatDoc = await getDoc(docToUpdate);

      if (!chatDoc.exists()) {
        throw new Error("Chat document does not exist");
      }

      const chatData = chatDoc.data() as ServerResult;

      const updatedData: Partial<Result> = {};
      if (!chatData.originalSql) {
        updatedData.originalSql = chatData.sql;
      }
      updatedData.sqlHistory = chatData.sqlHistory
        ? [...chatData.sqlHistory, chatData.sql]
        : [chatData.sql];
      updatedData.sql = sql;
      await updateDoc(docToUpdate, updatedData);
      const updateFields = {
        sql: sql,
        sqlHistory: updatedData.sqlHistory,
        originalSql: chatData.originalSql ? chatData.originalSql : chatData.sql,
      };

      updateResultsFields(updateFields);
      const { [currentResult?.id]: removed, ...newDataMap } = dataMap;
      setDataMap(newDataMap);
      await incrementUsageMetrics(userDocument.organization);
      showAlert({ variant: "success", message: _(msg`SQL updated successfully!`) });
    } catch (e) {
      console.error("Error updating SQL in chat record: ", e);
      showAlert({
        variant: "error",
        message: _(msg`Failed to update SQL. Please try again later.`),
      });
      throw e;
    }
  });

  const updateChartConfigInFirestore = withSentry(async (chartConfigurations: ChartConfig) => {
    try {
      if (!userDocument || !userDocument.organization) throw new Error("User document not found");

      const chatHistoryCollectionRef = collection(
        db,
        "organizations",
        userDocument.organization,
        "threads",
        currentThread,
        "chats"
      );

      const docToUpdate = doc(chatHistoryCollectionRef, currentResult?.id);
      const chatDoc = await getDoc(docToUpdate);

      if (!chatDoc.exists()) {
        throw new Error("Chat document does not exist");
      }

      await updateDoc(docToUpdate, {
        ...chatDoc.data(),
        chartConfigurations: chartConfigurations,
      });

      const updatedResults = allResults.map((result) => {
        if (result.id === currentResult?.id) {
          return {
            ...result,
            chartConfigurations: chartConfigurations,
          };
        }
        return result;
      });
      setAllResults(updatedResults);
      if (currentResult) {
        setCurrentResult({ ...currentResult, chartConfigurations });
      }

      showAlert({ variant: "success", message: _(msg`SQL updated successfully!`) });
    } catch (e) {
      console.error("Error updating SQL in chat record: ", e);
      showAlert({
        variant: "error",
        message: _(msg`Failed to update SQL. Please try again later.`),
      });
      throw e;
    }
  });

  const updateSatisfaction = withSentry(
    async (satisfaction: string, feedback: string = "", sendNotification: boolean = false) => {
      try {
        if (!userDocument || !userDocument.organization) throw new Error("User document not found");

        const chatHistoryCollectionRef = collection(
          db,
          "organizations",
          userDocument.organization,
          "threads",
          currentThread,
          "chats"
        );
        const newSatisfaction = { like: satisfaction, feedback: feedback };

        const updatedData = {
          satisfied: newSatisfaction,
        };
        const docToUpdate = doc(chatHistoryCollectionRef, currentResult!.id);
        await updateDoc(docToUpdate, updatedData);
        updateResultsFields(updatedData);

        if (sendNotification) {
          await reportNegativeFeedback(currentResult!.query, currentResult!.id);
        }

        Analytics.track("Gave Feedback", { satisfaction });
      } catch (e) {
        console.error("Error updating chat record: ", e);
        throw e;
      }
    }
  );

  const updateIndividualSatisfaction = withSentry(
    async (
      index: number,
      satisfaction: string,
      feedback: string = "",
      sendNotification: boolean = false
    ) => {
      try {
        if (!userDocument || !userDocument.organization) throw new Error("User document not found");

        const newSatisfaction = { like: satisfaction, feedback: feedback };
        allResults[index].satisfied = newSatisfaction;
        const updatedData = {
          satisfied: newSatisfaction,
        };

        const chatHistoryCollectionRef = collection(
          db,
          "organizations",
          userDocument.organization,
          "threads",
          currentThread,
          "chats"
        );
        const docToUpdate = doc(chatHistoryCollectionRef, allResults[index].id);
        await updateDoc(docToUpdate, updatedData);
        if (sendNotification) {
          await reportNegativeFeedback(allResults[index].query, allResults[index].id);
        }

        setAllResults([...allResults]);
      } catch (e) {
        console.error("Error updating chat record: ", e);
        throw e;
      }
    }
  );

  const fetchRelevantChats = withSentry(async () => {
    if (searchQuery === "") return;

    try {
      if (!userDocument || !userDocument.organization) throw new Error("User document not found");

      setLoadingSearchResults(true);
      const functionsInstance = getFirebaseFunctionsInstance();
      const fetchRelevantChats = createHttpsCallable(functionsInstance, "fetchRelevantChats");
      const request = {
        question: searchQuery,
        orgId: userDocument.organization,
      };
      const response = (await fetchRelevantChats(request)) as { data: string[] };
      setSearchResults(response?.data);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setLoadingSearchResults(false);
    }
  });

  const searchThreads = () => {
    if (!searchQuery) {
      setFilteredThreads(threads);
      return;
    }

    const lowercasedQuery = searchQuery.toLowerCase();
    const relevantThreadMappings = chatThreadMap.filter((item) =>
      item.query.toLowerCase().includes(lowercasedQuery)
    );

    const relevantThreadsIds = new Set(relevantThreadMappings.map((item) => item.thread_id));
    const fThreads = threads
      .filter((thread) => relevantThreadsIds.has(thread.id))
      .map((thread) => {
        const threadMapping: ThreadMap | undefined = relevantThreadMappings.find(
          (item) => item.thread_id === thread.id
        );

        if (!threadMapping) {
          return {
            ...thread,
            resultId: null,
            question: "",
          };
        }

        return {
          ...thread,
          resultId: threadMapping.id,
          question: threadMapping.query,
        };
      });

    setFilteredThreads(fThreads);
  };

  const selectResult = withSentry(async (result: Result) => {
    setCurrentResult(result);

    const index = allResults.findIndex((item) => item.id === result.id);
    if (index > 1) preFetchFirstPageData(allResults[index - 1]);
    if (index < allResults.length - 1) preFetchFirstPageData(allResults[index + 1]);
  });

  const deleteChatFromThread = withSentry(async function deleteChatFromThread() {
    try {
      const threadMapping = chatThreadMap.find((item) => item.id === currentResult?.id);
      const chatId = currentResult?.id;
      if (!threadMapping) {
        console.error("Thread mapping not found");
        throw new Error("Thread mapping not found");
      }
      const chatThreadMappingRef = doc(
        db,
        "organizations",
        userDocument?.organization,
        "chat_thread_mapping",
        chatId
      );
      await deleteDoc(chatThreadMappingRef);

      const chatRef = doc(
        db,
        "organizations",
        userDocument?.organization,
        "threads",
        threadMapping.thread_id,
        "chats",
        chatId
      );
      await deleteDoc(chatRef);
      const chatIndex = allResults.findIndex((item) => item.id === currentResult?.id);
      const updatedResults = allResults.filter((item) => item.id !== currentResult?.id);
      const updatedSearchHistory = searchHistory.filter((item, index) => index !== chatIndex);
      const updatedAnswers = answer.filter((item, index) => index !== chatIndex);
      setAllResults(updatedResults);
      setAnswer(updatedAnswers);
      setSearchHistory(updatedSearchHistory);
      setCurrentResult(updatedResults[chatIndex - 1] || updatedResults[0] || null);
      if (updatedResults.length === 0) {
        await deleteThread(chatThreadMap.find((item) => item.id === currentResult?.id)?.thread_id);
      }
      showAlert({ variant: "success", message: _(msg`Chat deleted successfully!`) });

      Analytics.track("Delete Chat");
    } catch (e) {
      console.error("Error deleting chat: ", e);
      showAlert({ variant: "error", message: _(msg`Error deleting chat!`) });
      throw e;
    }
  });

  /**
   * Updates specified fields in allResults and currentResult.
   *
   * @param updates An object with keys as the fields of Result and values as the new values for those fields.
   */
  const updateResultsFields = (updates: Partial<Result>) => {
    if (currentResult) {
      const updatedAllResults = allResults.map((result) =>
        result.id === currentResult.id ? { ...result, ...updates } : result
      );
      setAllResults(updatedAllResults);
      const updatedCurrentResult = { ...currentResult, ...updates };
      setCurrentResult(updatedCurrentResult);
    }
  };

  useEffect(() => {
    searchThreads();
  }, [searchQuery, chatThreadMap, threads]);

  useEffect(() => {
    let unsubscribe: Unsubscribe; // Initialize to a no-op function
    if (userDocument && userDocument.organization) {
      unsubscribe = fetchThreads()!; // Call fetchThreads and get the unsubscribe function
    } else {
      unsubscribe = () => {};
    }
    // Cleanup the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [userDocument]);

  const value = {
    currentResult,
    setCurrentResult,
    searchHistory,
    setSearchHistory,
    allResults,
    setAllResults,
    answer,
    setAnswer,
    threads,
    setThreads,
    currentThread,
    setCurrentThread,
    deleteThread,
    selectThread,
    fetchNextHistory,
    chatCount,
    chatHistoryIds,
    setChatHistoryIds,
    newChat,
    dynamicCache,
    setDynamicCache,
    updateSatisfaction,
    updateIndividualSatisfaction,
    updateTitle,
    searchQuery,
    setSearchQuery,
    searchResults,
    setSearchResults,
    fetchRelevantChats,
    loadingSearchResults,
    setLoadingSearchResults,
    filteredThreads,
    selectResult,
    loadingThread,
    setLoadingThread,
    dataMap,
    setDataMap,
    preFetchFirstPageData,
    navigateToChatFromPin,
    fetchSummaryFromPin,
    updateSqlInFirestore,
    updateChartConfigInFirestore,
    fetchingSuggestions,
    loading,
    setLoading,
    deleteChatFromThread,
    updateResultsFields,
    streamLoading,
    setStreamLoading,
  };

  return <ChatDataContext.Provider value={value}>{children}</ChatDataContext.Provider>;
};
