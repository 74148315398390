import * as Sentry from "@sentry/react";
import {
  UserCredential,
  getAuth,
  inMemoryPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithCustomToken,
} from "firebase/auth";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";

import { withSentry } from "../../helpers/wrapper";
import { Analytics } from "../../services/analytics/Analytics";
import {
  createNewDocumentInFirebase,
  db,
  sendPwdResetEmailWithFirebase,
  signInWithFirebase,
  signOutFromFirebase,
  signUpWithFirebase,
  updateLastSignIn,
} from "../../services/firebase";
import { AuthContextI, AuthUser, UserDocument } from "./auth.i";
import { fetchFeatureFlags } from "../../services/webserver/feature_flags";

const initialState: AuthContextI = {
  authUser: null,
  userDocument: null,
  organization: null,
  featureFlags: [],
  setUserDocument: (userDoc: DocumentData | null) => {},
  setFeatureFlags: () => {},
  isLoading: true,
  signOut: async () => {
    await signOutFromFirebase();
  },
  signIn: async (email, value): Promise<UserCredential> => {
    return await signInWithFirebase(email, value);
  },
  signUp: async (email, value) => {
    return await signUpWithFirebase(email, value);
  },
  resetPwd: async (email) => {
    return await sendPwdResetEmailWithFirebase(email);
  },
  createNewDocument: async (firstName: string, lastName: string, role: string) => {},
  fetchUserDocument: async (userId: string, org: string | null) => null,
  updateUserField: () => {},
  authenticateWithToken: async () => {},
  isCanvasAccount: false,
  setIsCanvasAccount: () => {},
  baseURL: "",
  accountId: "",
  setAccountId: () => {},
};

export default function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userDocument, setUserDocument] = useState<DocumentData | null>(null);
  const [organization, setOrganization] = useState<string | null>(null);
  const [isCanvasAccount, setIsCanvasAccount] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<string[]>([]);
  const [baseURL, setBaseURL] = useState<string>("");
  const [accountId, setAccountId] = useState<string>("");

  const clear = () => {
    setAuthUser(null);
    setUserDocument(null);
    setFeatureFlags([]);
    sessionStorage.clear();
  };

  const signOut = withSentry(async () => {
    await signOutFromFirebase();
    clear();
    setIsLoading(false);
  });

  const signIn = withSentry(async (email: string, password: string) => {
    try {
      setIsLoading(true);
      const response = await signInWithFirebase(email.toLowerCase(), password);
      return response;
    } catch (e) {
      throw e;
    } finally {
      Sentry.withScope((scope) => {
        scope.setTag("log-tag", "UserSignIn");
        scope.setTag("user_id", email);
        Sentry.captureMessage(`User Signed In, Email: ${email}`);
      });

      setIsLoading(false);
    }
  });

  const signUp = withSentry(async (email: string, password: string) => {
    try {
      setIsLoading(true);
      const response = await signUpWithFirebase(email.toLowerCase(), password);
      return response;
    } catch (e) {
      throw e;
    } finally {
      Sentry.withScope((scope) => {
        scope.setTag("log-tag", "UserSignUp");
        scope.setTag("user_id", email);
        Sentry.captureMessage(`User Signed Up, Email: ${email}`);
      });

      setIsLoading(false);
    }
  });

  const authenticateWithToken = withSentry(async (token: string) => {
    try {
      setIsLoading(true);
      const auth = getAuth();
      await setPersistence(auth, inMemoryPersistence);
      await signInWithCustomToken(auth, token);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  });

  const resetPwd = withSentry(async (email: string) => {
    try {
      setIsLoading(true);
      const response = await sendPwdResetEmailWithFirebase(email.toLowerCase());
      return response;
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  });

  const authStateChanged = withSentry(async (userCredential: AuthUser) => {
    setIsLoading(true);

    if (!userCredential) {
      clear();
      setIsLoading(false);
      return;
    }

    const decodedToken = await userCredential.getIdTokenResult();
    const orgFromToken = decodedToken.claims.org;
    const userDoc = await fetchUserDocument(userCredential.uid, orgFromToken);

    if (userDoc !== null) {
      setUserDocument(userDoc);
      await updateLastSignIn(userDoc.organization, userCredential.uid);
    }

    setAuthUser(userCredential);

    Analytics.login(userCredential.uid);

    const features = await fetchFeatureFlags();
    setFeatureFlags(features || []);
    setIsLoading(false);
  });

  //TODO: Temp check for if current account is a canvas account. Remove when canvas is fully integrated.
  const checkForCanvasSource = withSentry(async (orgId: string) => {
    try {
      const sourcesCollectionRef = collection(db, "organizations", orgId, "sources");
      const querySnapshot = await getDocs(
        query(sourcesCollectionRef, where("source_type", "==", "CANVAS"))
      );
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error checking for CANVAS source:", error);
      return false;
    }
  });

  /** Fetches the user document from Firestore, returns user data if document exists, null otherwise. */
  const fetchUserDocument = withSentry(
    async (userId: string, org?: string): Promise<UserDocument | null> => {
      try {
        const orgId = org ?? (await fetchOrganizationId(userId));
        console.info("*** orgId", orgId);
        if (!orgId) {
          return null;
        }

        Analytics.addUserInformation({ organization: orgId });

        const url = await fetchBaseUrl(orgId);
        setBaseURL(url);
        const userDocSnapshot = await getDoc(doc(db, "organizations", orgId, "users", userId));
        if (!userDocSnapshot.exists()) {
          console.error("User document does not exist in the specified organization.");
          return null;
        }
        const hasCanvasSource = await checkForCanvasSource(orgId);
        setIsCanvasAccount(hasCanvasSource);

        await fetchOrganizationName(orgId);

        return userDocSnapshot.data() as UserDocument;
      } catch (error) {
        console.error("Error fetching user document:", error);
        return null;
      }
    }
  );

  async function fetchBaseUrl(orgId) {
    const docRef = doc(db, "organizations", orgId);
    let defaultUrl = "https://app.doowii.io";

    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && docSnap.data().base_url) {
        return docSnap.data().base_url;
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
    return defaultUrl;
  }

  async function fetchOrganizationId(userId: string) {
    const userOrgsDocRef = doc(db, "user_orgs", userId);
    const userOrgsDocSnapshot = await getDoc(userOrgsDocRef);
    if (!userOrgsDocSnapshot.exists()) {
      console.error("User organization document does not exist.");
      return null;
    }

    return userOrgsDocSnapshot.data().organization;
  }

  async function fetchOrganizationName(orgId: string) {
    const orgsDocRef = doc(db, "organizations", orgId);
    const orgsDocSnapshot = await getDoc(orgsDocRef);

    if (!orgsDocSnapshot.exists()) {
      console.error("Organization document does not exist.");
      return null;
    }

    setOrganization(orgsDocSnapshot.data().name);
  }

  /**
   * Funciton to create a new document for when a user registers for the first time.
   * @param {*} firstName
   * @param {*} lastName
   * @param {*} dob
   * @param {*} organization
   * @param {*} role
   */
  const createNewDocument = withSentry(
    async (firstName: string, lastName: string, role: string) => {
      if (!authUser) throw new Error("User not authenticated");

      try {
        setIsLoading(true);
        await createNewDocumentInFirebase(firstName, lastName, role, authUser);

        const userOrgsDocRef = doc(db, "user_orgs", authUser.uid);
        const userOrgsDocSnapshot = await getDoc(userOrgsDocRef);

        if (userOrgsDocSnapshot.exists()) {
          const orgId = userOrgsDocSnapshot.data().organization;
          const userDocRef = doc(db, "organizations", orgId, "users", authUser.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) setUserDocument(userDocSnapshot.data());
        }
      } catch (e) {
        throw e;
      } finally {
        setIsLoading(false);
      }
    }
  );

  const updateUserField = withSentry(async (field: keyof UserDocument, value: any) => {
    if (!authUser || !userDocument) throw new Error("User not authenticated");

    try {
      const userDocRef = doc(
        db,
        "organizations",
        userDocument?.organization,
        "users",
        authUser?.uid
      );

      const update: Partial<AuthUser> = {
        [field]: value,
      };

      await updateDoc(userDocRef, update);
    } catch (e) {
      console.error(`Error updating ${field}: `, e);
      throw e;
    }
  });

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, authStateChanged as any); // workaround for null user
    return () => {
      unsubscribe();
    };
  }, []);

  return {
    authUser,
    userDocument,
    featureFlags,
    setUserDocument,
    setFeatureFlags,
    isLoading,
    signOut,
    signIn,
    signUp,
    resetPwd,
    createNewDocument,
    fetchUserDocument,
    updateUserField,
    authenticateWithToken,
    isCanvasAccount,
    setIsCanvasAccount,
    baseURL,
    organization,
    accountId,
    setAccountId,
  };
}

const AuthUserContext = createContext(initialState);

export const AuthUserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const auth = useFirebaseAuth();
  return <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>;
};

export const useAuth = () => useContext(AuthUserContext);
