import { CSS } from "@dnd-kit/utilities";
import { Button } from "@instructure/ui";
import { FC, useEffect, useState } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { IconDragHandleLine } from "@instructure/ui-icons";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useChatData } from "../../../../../context/chat";
import { usePinboard } from "../../../../../context/pinboard";
import { Pin } from "../../../../../context/pinboard/pinboard.i";
import { useUI } from "../../../../../context/ui";
import { ViewEnum } from "../../../../../context/ui/ui.i";
import { withSentry } from "../../../../../helpers/wrapper";
import useBreakpoint from "../../../../../hooks/useBreakpoint";
import { Analytics } from "../../../../../services/analytics/Analytics";
import { ChartType } from "../../../../types";
import { DeleteModal } from "../../../UI/Modals/Delete";
import { getImageSrc } from "../../helpers";
import { MovePopover } from "../../MovePopover";
import { PinFullScreenView } from "../../PinFullScreenView/components/PinFullScreenView";
import { ActionButtons } from "./ActionButtons";
import { ChartTitle } from "./ChartTitle";

interface PinboardListItemProps {
  chart?: Pin;
  openMoveMenuId?: string | number;
  setOpenMoveMenuId?: (value: string | number | null) => void;
  filteredPinboardResults?: Pin[];
  setFilteredPinboardResults?: React.Dispatch<React.SetStateAction<Pin[]>>;
  setPinboardResults?: React.Dispatch<React.SetStateAction<Pin[]>>;
  hasSearch?: boolean;
}

const PinboardListItem: FC<PinboardListItemProps> = ({
  chart,
  openMoveMenuId,
  setOpenMoveMenuId,
  filteredPinboardResults,
  setFilteredPinboardResults,
  setPinboardResults,
  hasSearch,
}) => {
  const { _ } = useLingui();

  const { updatePinTitle, unpinFromBoard, currBoardId } = usePinboard();
  const { navigateToChatFromPin } = useChatData();
  const { setView } = useUI();

  const [isFullScreen, setFullScreen] = useState(false);
  const [deleteChartModalOpen, setDeleteChartModalOpen] = useState(false);
  const [value, setValue] = useState(chart?.title);
  const [readOnly, setReadOnly] = useState(true);

  const handleViewChat = withSentry(async () => {
    await navigateToChatFromPin(chart?.id);

    setView(ViewEnum.CHAT_VIEW);
  });
  const { isChatDesktop } = useBreakpoint();

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id: chart?.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  useEffect(() => {
    if (isFullScreen) {
      Analytics.track("Pinboard full screen");
    }
  }, [isFullScreen]);

  return (
    <>
      <div
        style={style}
        ref={setNodeRef}
        className={`relative flex items-center justify-between border-b border-[#D7DADE] py-3 first:border-t ${
          isDragging ? "bg-gray-100" : "bg-white"
        }`}
      >
        <div className="flex flex-1 items-center gap-6">
          {!hasSearch && isChatDesktop && (
            <div {...listeners} {...attributes} ref={setActivatorNodeRef} aria-label={chart?.title}>
              <IconDragHandleLine size="x-small" />
            </div>
          )}

          {openMoveMenuId === chart?.id && (
            <MovePopover
              pinId={chart?.id}
              setOpenMoveMenuId={setOpenMoveMenuId}
              filteredPinboardResults={filteredPinboardResults}
              setFilteredPinboardResults={setFilteredPinboardResults}
              setPinboardResults={setPinboardResults}
            />
          )}
          <img
            src={getImageSrc(chart?.visualisation as unknown as ChartType)} // Types need to be unified
            alt={`${chart?.visualisation} chart`}
          />
          <ChartTitle results={chart} setValue={setValue} value={value} readOnly={readOnly} />
        </div>

        <div className="flex min-w-fit flex-[1] items-center justify-end">
          <div className="flex">
            <Button size="small" margin="x-small" onClick={() => handleViewChat()}>
              <Trans>View Chat</Trans>
            </Button>
            <Button
              size="small"
              margin="x-small small x-small x-small"
              onClick={() => setFullScreen(true)}
            >
              <Trans>Full Screen</Trans>
            </Button>
          </div>

          <ActionButtons
            setDeleteChartModalOpen={setDeleteChartModalOpen}
            value={value}
            pinResult={chart}
            readOnly={readOnly}
            setReadOnly={setReadOnly}
            updateTitle={updatePinTitle}
            setValue={setValue}
          />
        </div>
      </div>

      {setDeleteChartModalOpen && (
        <DeleteModal
          open={deleteChartModalOpen}
          setOpen={setDeleteChartModalOpen}
          action={() => (unpinFromBoard as any)(chart?.id, currBoardId)}
          name={_(msg`Chart`)}
          text={_(msg`Are you sure you want to delete this chart?`)}
        />
      )}
      {isFullScreen && (
        <PinFullScreenView
          open={isFullScreen}
          setOpen={setFullScreen}
          chart={chart}
          setDeleteChartModalOpen={setDeleteChartModalOpen}
        />
      )}
    </>
  );
};

export { PinboardListItem };
