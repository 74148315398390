import { Alert } from "@instructure/ui";
import { FC, ReactNode, createContext, useContext, useState } from "react";

import { ALERT_TIMEOUT } from "../../Constants/constants";
import { AlertContextI, AlertPromiseOptions, AlertState } from "./alert.i";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

const initialState: AlertContextI = {
  alert: null,
  showAlert: (alert: AlertState) => {},
  closeAlert: () => {},
  alertPromise: async <T,>(options: AlertPromiseOptions<T>): Promise<T> => {
    throw new Error("AlertContext not initialized");
  },
};

const AlertContext = createContext(initialState);

export const AlertProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { _ } = useLingui();
  const [alert, setAlert] = useState<AlertState | null>(null);

  const showAlert = (alert: AlertState) => setAlert(alert);
  const closeAlert = () => setAlert(null);

  const alertPromise = async <T,>({
    promise,
    loading,
    success,
    error = _(msg`Operation failed`),
  }: AlertPromiseOptions<T>): Promise<T> => {
    showAlert({ variant: "info", message: loading });

    try {
      const result = await promise();
      closeAlert();
      showAlert({ variant: "success", message: success });
      return result;
    } catch (err) {
      closeAlert();
      const errorMessage = typeof error === "function" ? error(err as Error) : error;
      showAlert({ variant: "error", message: errorMessage });
      throw err;
    }
  };

  const value = {
    alert,
    showAlert,
    closeAlert,
    alertPromise,
  };

  return (
    <>
      {alert && (
        <div
          style={{
            position: "fixed",
            zIndex: 9999999999,
            top: "16px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "90%",
            maxWidth: 400,
          }}
        >
          <Alert
            variant={alert.variant}
            renderCloseButtonLabel={_(msg`Close`)}
            onDismiss={closeAlert}
            margin="small 0"
            transition="fade"
            timeout={ALERT_TIMEOUT}
          >
            {alert.message}
          </Alert>
        </div>
      )}

      <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
    </>
  );
};

export const useAlert = () => useContext(AlertContext);
