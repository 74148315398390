import React, { useMemo, useCallback } from "react";
import { Chart, Chart as HighchartsChart } from "highcharts";
import { useResize } from "../../../hooks/useResize";
import { ChartType } from "../../types";
import MemoizedBarChartH from "../../../components/Charts/BarChart/BarChartH";
import MemoizedLineChartH from "../../../components/Charts/LineChart/LineChartH";
import MemoizedPieChartH from "../../../components/Charts/PieChart/PieChartH";
import MemoizedScatterChartH from "../../../components/Charts/ScatterChart/ScatterChartH";
import { ChartProps } from "../ChartBuilder/ChartBuilder";
import { useChart } from "../../../context/chart";
import { ChartContextI } from "../../../context/chart/chart.i";
import { useChartQuery } from "../../../hooks/useChartQuery";
import { ChartErrorState } from "../../../components/Charts/ChartErrorState/ChartErrorState";
import SingleMetricChart from "../../../components/Charts/SingleMetric/SingleMetric";
import { useChatData } from "../../../context/chat";

const ChartRenderer: React.FC<{ chartProps: ChartProps }> = ({ chartProps }) => {
  const { componentRef, width, height } = useResize();
  const { dataMap } = useChatData();
  const { setChartInstances } = useChart();

  const handleChartInstance = useCallback(
    (chart: HighchartsChart | null) => {
      if (chart) {
        setChartInstances((prev: ChartContextI["chartInstances"]) => [
          ...prev,
          { id: chartProps.docId, chart: chart },
        ]);
      }
      return () => {
        setChartInstances((prev: ChartContextI["chartInstances"]) =>
          prev.filter((c) => c.id !== chartProps.docId)
        );
      };
    },
    [setChartInstances]
  );

  const {
    data: chartData,
    isError,
    // isLoading,
  } = useChartQuery({
    sqlQuery: chartProps.sql,
    chartType: chartProps.chartType,
    selectedColumns: Object.keys(chartProps.selectedColumns),
    chartConfig: chartProps.chartConfig,
    docId: chartProps.docId,
    parentDocId: chartProps.parentDocId,
    parentDocType: chartProps.parentDocType,
  });

  const renderChart = useMemo(() => {
    if (width === 0 || height === 0) return null;

    const props = {
      ...chartProps,
      data: chartData,
      chartConfig: {
        ...chartProps.chartConfig,
        columns: Object.keys(chartProps.selectedColumns),
      },
      width,
      height,
      onChartInstance: handleChartInstance,
    };

    switch (chartProps.chartType) {
      case ChartType.BAR_CHART:
        return <MemoizedBarChartH {...props} />;
      case ChartType.LINE_CHART:
        return <MemoizedLineChartH {...props} />;
      case ChartType.PIE_CHART:
        return <MemoizedPieChartH {...props} />;
      case ChartType.SCATTER_CHART:
        return <MemoizedScatterChartH {...props} />;
      case ChartType.SINGLE_METRIC:
        const data = dataMap[chartProps.docId];
        const value = data?.rows[0]?.[data?.pageInfo?.headers[0]] || 0;
        const kpiName = chartProps.chartConfig?.options?.KPIName || "Metric";
        return (
          <SingleMetricChart
            loading={chartProps.loading}
            value={value}
            kpiName={kpiName}
            height={height - 100}
            width={width - 100}
          />
        );
      default:
        return null;
    }
  }, [chartProps, width, height, handleChartInstance, chartData, dataMap[chartProps.docId]]);

  return (
    <div
      ref={componentRef}
      className="flex w-full flex-grow flex-col justify-center overflow-hidden border-none"
    >
      <div className="chart-renderer self-center">
        {isError ? <ChartErrorState /> : renderChart}
      </div>
    </div>
  );
};

export { ChartRenderer };
