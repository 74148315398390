import React, { useState, useMemo, useEffect } from "react";
import { ChartType } from "../../types";
import { Button, Checkbox, CheckboxGroup, Text, TextInput } from "@instructure/ui";
import { canvas } from "@instructure/ui-themes";
import { TableColumnType } from "../../../context/chart/chart.i";
import { ChartRenderer } from "../ChartRenderer/ChartRenderer";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  getChartTitle,
  getColumnLabel,
  renderChartIcon,
  DataType,
  getAvailableDataTypes,
  availableBuilderCharts,
} from "./helpers";
import { IconArrowOpenStartLine } from "@instructure/ui-icons";
import { Result } from "../../../context/chat/chat.i";
import { Pin, VisualizationTypesEnum } from "../../../context/pinboard/pinboard.i";
import { ParentDocTypeEnum } from "../../../api/retriever.i";
import { useChatData } from "../../../context/chat";
import { useQuery } from "../../../hooks/useQuery";
import { DEFAULT_QUERY_OPTIONS } from "../../../Constants/constants";
import { ViewEnum } from "../../../context/ui/ui.i";
import { usePinboard } from "../../../context/pinboard";

export interface ChartColumnType {
  name: string;
  type: DataType;
  isSelected: boolean;
}

export interface ChartProps {
  selectedColumns: Record<string, TableColumnType>;
  docId: string;
  sql: string;
  chartConfig: any;
  title: string;
  loading: boolean;
  error: boolean;
  // Is this still needed? Used for max row warning
  totalRows?: number;
  chartType: PlottableChartTypes;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
}

interface ChartBuilderProps {
  result: Result | Pin;
  didCreateChart?: (chartProps: ChartProps) => void;
  view: ViewEnum.CHAT_VIEW | ViewEnum.PINBOARD_VIEW;
}

export type PlottableChartTypes =
  | ChartType.BAR_CHART
  | ChartType.PIE_CHART
  | ChartType.LINE_CHART
  | ChartType.SCATTER_CHART
  | ChartType.SINGLE_METRIC;

export const allCanvasCharts: PlottableChartTypes[] = [
  ChartType.BAR_CHART,
  ChartType.PIE_CHART,
  ChartType.LINE_CHART,
  ChartType.SCATTER_CHART,
  ChartType.SINGLE_METRIC,
];

export const ChartBuilder: React.FC<ChartBuilderProps> = ({ result, didCreateChart, view }) => {
  const { _ } = useLingui();
  const [columns, setColumns] = useState<ChartColumnType[]>([]);
  const [selectedChart, setSelectedChart] = useState<PlottableChartTypes | null>(null);
  const [availableCharts, setAvailableCharts] = useState<PlottableChartTypes[]>([]);
  const [showChart, setShowChart] = useState(false);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [KPIName, setKPIName] = useState("");
  const { dataMap, updateResultsFields } = useChatData();
  const { updatePinFields } = usePinboard();

  const { loading } = useQuery(result, { paginationModel: DEFAULT_QUERY_OPTIONS }, shouldFetchData);

  useEffect(() => {
    if (result?.id && !dataMap[result.id]) {
      setShouldFetchData(true);
    }
    if (result?.id && dataMap[result.id]) {
      setShouldFetchData(false);
    }
  }, [result, dataMap]);

  useEffect(() => {
    if (result?.id) {
      const chartConfig = result.chartConfig || (result as Pin).chart_config;
      if (selectedChart === ChartType.SINGLE_METRIC) {
        setKPIName(chartConfig?.options?.KPIName || columns[0]?.name);
      }
    }
  }, [result?.id, selectedChart]);

  useEffect(() => {
    if (selectedChart === ChartType.SINGLE_METRIC) {
      const currentKPIName = result.chartConfig?.options?.KPIName || "";
      if (KPIName !== currentKPIName) {
        if (view === ViewEnum.CHAT_VIEW) {
          updateResultsFields({
            chartConfig: {
              ...result.chartConfig,
              options: { ...result.chartConfig?.options, KPIName },
            },
          });
        } else {
          updatePinFields(result?.id, {
            chartConfig: {
              ...result.chartConfig,
              options: { ...result.chartConfig?.options, KPIName },
            },
          });
        }
      }
    }
  }, [selectedChart, KPIName, result.chartConfig, updateResultsFields, updatePinFields]);

  useEffect(() => {
    if (result?.id) {
      // TODO: Remove this once we check chart_config is not used anymore
      const chartConfig = result.chartConfig || (result as Pin).chart_config;
      const selectedColumns = (result as Pin)?.selectedColumns;
      const allColumns: ChartColumnType[] = Object.entries(chartConfig.column_types).map(
        ([columnName, columnType]) => ({
          name: columnName,
          type: chartConfig.column_types[columnName],
          isSelected: selectedColumns?.[columnName] ? true : false,
        })
      );
      setColumns(allColumns);
      // @ts-ignore
      const charts = availableBuilderCharts(
        allColumns,
        dataMap[result?.id]?.pageInfo?.totalRowCount
      );
      setAvailableCharts(charts);

      if ((result as Pin)?.visualisation !== VisualizationTypesEnum.TABLE) {
        //TODO: Standardize chart types
        setSelectedChart((result as Pin)?.visualisation as unknown as PlottableChartTypes);
        setShowChart(true);
      } else {
        setSelectedChart(null);
        setShowChart(false);
      }
    }
  }, [result?.id, dataMap[result?.id]]);

  const handleChartSelect = (chart: PlottableChartTypes) => {
    if (chart !== selectedChart) {
      setSelectedChart(chart);
      setColumns((cols) => cols.map((col) => ({ ...col, isSelected: false })));
    }
  };

  const handleColumnSelect = (selectedValues: string[]) => {
    setColumns((cols) =>
      cols.map((col) =>
        selectedValues.includes(col.name)
          ? { ...col, isSelected: true }
          : { ...col, isSelected: false }
      )
    );
  };

  const selectedColumns = useMemo(() => columns.filter((col) => col.isSelected), [columns]);

  const availableColumns = useMemo(() => {
    if (!selectedChart) return columns;

    const selectedTypes = selectedColumns.map((col) => col.type);
    if (selectedTypes.length === 2) {
      return selectedColumns;
    }
    const availableDataTypes = getAvailableDataTypes({ selectedTypes, chart: selectedChart });
    return columns.filter((col) => availableDataTypes.includes(col.type));
  }, [columns, selectedChart, selectedColumns]);

  const chartProps: ChartProps = useMemo(() => {
    let totalRows = 0;
    const selectedColumnObjects: ChartProps["selectedColumns"] = selectedColumns.reduce(
      (acc, col) => {
        acc[col.name] = {
          type: col.type,
          name: col.name,
        };
        return acc;
      },
      {}
    );
    const chartConfig = { ...(result.chartConfig || (result as Pin).chart_config) };
    if (selectedChart === ChartType.SINGLE_METRIC) {
      chartConfig.options = { ...chartConfig.options, KPIName: KPIName };
    }

    return {
      chartConfig,
      selectedColumns: selectedColumnObjects,
      docId: result.id,
      title: result.title,
      loading: loading,
      error: false,
      totalRows,
      chartType: selectedChart,
      sql: result.sql,
      parentDocId: result.parentDocId,
      parentDocType: result.parentDocType,
    };
  }, [selectedColumns, selectedChart, result, KPIName, loading]);

  if (showChart) {
    return (
      <div className="flex w-full flex-grow flex-col pt-6">
        <div className="w-fit self-start">
          <Button
            withBackground={false}
            color="primary"
            renderIcon={<IconArrowOpenStartLine />}
            onClick={() => setShowChart(false)}
            themeOverride={{
              borderWidth: "0",
            }}
          >
            <Trans>Chart Builder</Trans>
          </Button>
        </div>
        <ChartRenderer chartProps={chartProps} />
      </div>
    );
  }

  return (
    <div className="flex max-h-32 flex-col px-4 pt-6">
      <Text weight="bold">
        <Trans>STEP 1: Select an applicable data visualization</Trans>
      </Text>
      <div className="my-4 flex flex-wrap gap-2">
        {allCanvasCharts.map((chart) => (
          <Button
            key={chart}
            onClick={() => handleChartSelect(chart)}
            className={selectedChart === chart ? "selected" : ""}
            renderIcon={renderChartIcon({ type: chart }, loading)}
            disabled={!availableCharts.includes(chart)}
            themeOverride={{
              secondaryBorderColor:
                selectedChart === chart ? canvas.colors.border.borderBrand : "auto",
              borderWidth: "0.1rem",
            }}
          >
            {_(getChartTitle(chart))}
          </Button>
        ))}
      </div>

      {selectedChart && (
        <>
          {selectedChart === ChartType.SINGLE_METRIC ? (
            <TextInput
              value={KPIName}
              onChange={(e) => setKPIName(e.target.value)}
              width="50%"
              renderLabel={<Trans>STEP 2: Add label to KPI</Trans>}
            />
          ) : (
            <>
              <CheckboxGroup
                name="columns"
                description={
                  <Text weight="bold">
                    <Trans>STEP 2: Select the applicable data types</Trans>
                  </Text>
                }
                value={selectedColumns.map((col) => col.name)}
                onChange={(values) => handleColumnSelect(values as string[])}
              >
                {columns.map((column) => {
                  return (
                    <Checkbox
                      key={column.name}
                      disabled={!column.isSelected && !availableColumns.includes(column)}
                      label={getColumnLabel(column)}
                      value={column.name}
                    />
                  );
                })}
              </CheckboxGroup>
            </>
          )}
          <div className="my-4 w-fit">
            <Button
              onClick={() => {
                setShowChart(true);
                if (didCreateChart) {
                  didCreateChart(chartProps);
                }
              }}
              disabled={selectedColumns.length < 2 && selectedChart !== ChartType.SINGLE_METRIC}
            >
              <Trans>Generate</Trans>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
