import {
  QueryDocumentSnapshot,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";

import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ParentDocTypeEnum } from "../../api/retriever.i";
import { TABLE } from "../../Constants/constants";
import { withSentry } from "../../helpers/wrapper";
import { Analytics } from "../../services/analytics/Analytics";
import { db } from "../../services/firebase";
import { fetchPins } from "../../services/webserver/pinboards";
import { useAlert } from "../alert";
import { useAuth } from "../auth";
import { TableColumns } from "../chart/chart.i";
import {
  Board,
  CreatePin,
  FirebaseTimestamp,
  Pin,
  PinboardContextI,
  VisualizationTypesEnum,
} from "./pinboard.i";

const initialState: PinboardContextI = {
  boards: {},
  currBoardId: null,
  loading: false,
  pinboardResults: [],
  setBoards: () => {},
  setCurrBoardId: () => {},
  setPinboardResults: () => {},
  addNewBoard: async (name: string) => "",
  deleteBoard: async () => {},
  updateBoardName: async () => {},
  fetchPinboardResultsForBoard: async () => {},
  pinToBoard: async () => {},
  unpinFromBoard: async () => {},
  updatePinTitle: async () => {},
  updatePinVisualization: async () => {},
  updatePinFields: (pinId: string, updates: Partial<Pin>) => {},
  dataLastUpdated: null,
};

const PinboardContext = createContext<PinboardContextI>(initialState);
export const usePinboard = () => useContext(PinboardContext);

export const PinboardProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { _ } = useLingui();
  const [boards, setBoards] = useState<Map<string, Board>>(new Map());
  const [currBoardId, setCurrBoardId] = useState<string | null>(null);
  const [pinboardResults, setPinboardResults] = useState<Pin[]>([]);
  const [loading, setLoading] = useState(false);
  const [dataLastUpdated, setDataLastUpdated] = useState<FirebaseTimestamp | null>(null);

  const { userDocument } = useAuth();
  const { showAlert } = useAlert();

  useEffect(() => {
    setLoading(true);

    const userId = userDocument?.id;
    if (!userId) {
      setLoading(false);
      return;
    }

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardsCollection = collection(organizationRef, "pinboards");
    const createdByQuery = query(pinboardsCollection, where("created_by", "==", userId));
    const canEditQuery = query(pinboardsCollection, where("can_edit", "array-contains", userId));

    const mergeAndUpdateBoards = (docSnap: QueryDocumentSnapshot<any>) => {
      const updatedBoards = new Map([...boards, [docSnap.id, docSnap.data()]]);
      setBoards((prevBoards) => new Map([...prevBoards, [docSnap.id, docSnap.data()]]));

      if (updatedBoards.size > 0) {
        setCurrBoardId((prevCurrBoardId) =>
          prevCurrBoardId === null ? updatedBoards.keys().next().value : prevCurrBoardId
        );
      } else {
        setCurrBoardId(null);
      }
    };

    const unsubscribeCreatedBy = onSnapshot(createdByQuery, (snapshot) => {
      snapshot.forEach(mergeAndUpdateBoards);
    });

    const unsubscribeCanEdit = onSnapshot(canEditQuery, (snapshot) => {
      snapshot.forEach(mergeAndUpdateBoards);
    });
    fetchLastUpdated();
    setLoading(false);

    // Cleanup the listeners on component unmount
    return () => {
      unsubscribeCreatedBy();
      unsubscribeCanEdit();
    };
  }, [userDocument]);

  useEffect(() => {
    // Whenever the current board ID changes, fetch the results for that board
    setPinboardResults([]);
    fetchPinboardResultsForBoard();
  }, [currBoardId]);

  const fetchLastUpdated = async () => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }

      const organizationRef = doc(db, "organizations", userDocument.organization);
      const sourceCollection = collection(organizationRef, "sources");

      const sourceQuery = query(sourceCollection, where("source_type", "==", "CANVAS"));
      const sourceSnapshot = await getDocs(sourceQuery);

      if (!sourceSnapshot.empty) {
        const sourceDoc = sourceSnapshot.docs[0];
        const lastUpdated = sourceDoc.data()?.last_updated;

        if (lastUpdated && lastUpdated.seconds) {
          setDataLastUpdated(lastUpdated);
        } else {
          setDataLastUpdated(null);
        }
      } else {
        setDataLastUpdated(null);
      }
    } catch (error) {
      console.error("Error fetching last updated:", error);
      setDataLastUpdated(null);
    }
  };

  const getRandomChipColor = () => {
    const colorThemeArray = [
      "default",
      "primary",
      "secondary",
      "error",
      "info",
      "success",
      "warning",
    ];
    return colorThemeArray[Math.floor(Math.random() * colorThemeArray.length)];
  };

  const addNewBoard = withSentry(async (name: string) => {
    if ([...boards.values()].some((board) => board.name.toLowerCase() === name.toLowerCase())) {
      throw new Error("Pinboard name already exists!");
    }
    // Add new board logic here
    if (!userDocument) {
      throw new Error("User not found!");
    }

    const userId = userDocument.id;
    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardsCollection = collection(organizationRef, "pinboards");
    const userDocRef = doc(organizationRef, "users", userId);

    const uuid = uuidV4();

    const pinboardData: CreatePin = {
      created_by: userId,
      can_edit: [],
      can_view: [],
      pins: [],
      name: name,
      id: uuid,
      created_at: serverTimestamp(),
      last_updated: serverTimestamp(),
      color: getRandomChipColor(),
    };

    try {
      await setDoc(doc(pinboardsCollection, uuid), pinboardData);
      await updateDoc(userDocRef, {
        pinboards: arrayUnion(uuid),
      });
      setCurrBoardId(uuid);
      console.info("Pinboard created successfully!");
      Analytics.track("Pinboard Created");
    } catch (error) {
      console.error("Error creating pinboard:", error);
      throw error;
    }

    return uuid;
  });

  const deleteBoard = withSentry(async (boardId: string) => {
    if (!userDocument) {
      throw new Error("User not found!");
    }
    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardsCollection = collection(organizationRef, "pinboards");
    const usersCollection = collection(organizationRef, "users");

    const pinboardDocRef = doc(pinboardsCollection, boardId);

    try {
      // Fetch the pinboard document
      const pinboardDocSnap = await getDoc(pinboardDocRef);

      if (!pinboardDocSnap.exists()) {
        console.error("Pinboard not found!");
        return;
      }

      const pinboardData = pinboardDocSnap.data();
      const allUsersToUpdate = [...pinboardData.can_edit, ...pinboardData.can_view];
      allUsersToUpdate.push(pinboardData.created_by);

      // Iterate over users and remove the pinboard UUID from their pinboards array
      const userUpdates = allUsersToUpdate.map(async (userId) => {
        const userDocRef = doc(usersCollection, userId);
        return updateDoc(userDocRef, {
          pinboards: arrayRemove(boardId),
        });
      });

      // Wait for all user updates to complete
      await Promise.all(userUpdates);
      const pinsCollectionRef = collection(pinboardDocRef, "pins");
      const pinsSnapshot = await getDocs(pinsCollectionRef);

      const batch = writeBatch(db);

      pinsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Delete the pinboard after deleting all pins
      batch.delete(pinboardDocRef);

      // Commit the batch
      await batch.commit();

      const newBoards = new Map(boards);
      newBoards.delete(boardId);
      setBoards(newBoards);

      if (newBoards.size > 0) {
        setCurrBoardId(newBoards.keys().next().value);
      } else {
        setCurrBoardId(null);
      }

      console.info("Pinboard deleted and UUIDs removed from user's pinboards arrays!");
      Analytics.track("Pinboard Deleted");
    } catch (error) {
      console.error("Error deleting pinboard and updating users:", error);
      throw error;
    }
  });

  const updateBoardName = withSentry(async (boardId: string, name: string) => {
    if (!userDocument) {
      throw new Error("User not found!");
    }
    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", boardId);

    try {
      await updateDoc(pinboardDocRef, { name });
      console.info("Pinboard name updated successfully!");
      showAlert({ variant: "success", message: _(msg`Pinboard name updated successfully!`) });

      Analytics.track("Pinboard Renamed");
    } catch (error) {
      console.error("Error updating pinboard name:", error);
      showAlert({
        variant: "error",
        message: _(msg`Failed to update Pinboard name. Please try again later.`),
      });

      throw error;
    }
  });

  const pinToBoard = withSentry(async (result: Pin, boardId: string) => {
    if (!userDocument) {
      throw new Error("User not found!");
    }

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", boardId);
    const pinRef = doc(pinboardDocRef, "pins", result.id);

    const newPinDoc = {
      query: result?.query,
      sql: result?.sql ?? "",
      originalSql: result?.originalSql ?? result?.sql ?? "",
      title: result?.title,
      visualisation: result?.visualisation,
      selectedColumns: result?.visualisation === TABLE ? {} : result?.selectedColumns ?? {},
      timestamp: new Date(),
      id: result.id,
      can_view: [],
      can_edit: [],
      created_at: new Date(),
      last_updated: new Date(),
      type: result?.type || "QUERY",
      status: result?.status || "success",
      chartConfig: result?.chartConfig || {},
    };
    try {
      await setDoc(pinRef, newPinDoc);
      await updateDoc(pinboardDocRef, { pins: arrayUnion(result.id) });
      if (currBoardId === boardId) {
        fetchPinboardResultsForBoard();
      }
      Analytics.track("Pinboard Pinned");
    } catch (error) {
      console.error("Error pinning result:", error);
      throw error;
    }
  });

  const unpinFromBoard = withSentry(async (resultId: string, boardId: string) => {
    if (!userDocument) {
      throw new Error("User not found!");
    }

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", boardId);
    const pinRef = doc(pinboardDocRef, "pins", resultId);

    try {
      // Remove the resultId from the pins array in the pinboards document
      await updateDoc(pinboardDocRef, { pins: arrayRemove(resultId) });

      // Delete the corresponding document in the pins subcollection
      await deleteDoc(pinRef);

      if (currBoardId === boardId) {
        setPinboardResults((prevResults) => prevResults.filter((result) => result.id !== resultId));
      }

      Analytics.track("Pinboard Unpinned");
    } catch (error) {
      console.error("Error unpinning result:", error);
      throw error;
    }
  });

  const fetchPinboardResultsForBoard = withSentry(async () => {
    // Ensure we have a board ID to fetch results for
    if (!currBoardId) return;

    if (!userDocument) {
      throw new Error("User not found!");
    }

    setLoading(true);
    const constructedPins = await fetchPins(currBoardId);
    const updatedPins = constructedPins.map((pin) => ({
      ...pin,
      parentDocId: currBoardId,
      parentDocType: ParentDocTypeEnum.PINBOARD,
    }));
    setPinboardResults([...updatedPins]);
    setLoading(false);
  });

  const updatePinTitle = withSentry(async (pinboardId: string, pinId: string, title: string) => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }

      const organizationRef = doc(db, "organizations", userDocument.organization);
      const pinboardDocRef = doc(organizationRef, "pinboards", pinboardId);
      const pinRef = doc(pinboardDocRef, "pins", pinId);
      await updateDoc(pinRef, { title: title });

      if (currBoardId === pinboardId) {
        setPinboardResults((prevResults) =>
          prevResults.map((result) => (result.id === pinId ? { ...result, title } : result))
        );
      }

      console.info("Pin title updated successfully!");
      showAlert({ variant: "success", message: _(msg`Pin title updated successfully!`) });
    } catch (error) {
      console.error("Error updating pin title:", error);
      showAlert({
        variant: "error",
        message: _(msg`Error updating pin title! Please try again later.`),
      });
      throw error;
    }
  });

  const updatePinVisualization = withSentry(
    async (
      pinboardId: string,
      pinId: string,
      selectedColumns: TableColumns,
      visualisation: VisualizationTypesEnum,
      KPIName?: string
    ) => {
      try {
        if (!userDocument) {
          throw new Error("User not found!");
        }

        let _selectedColumns = visualisation === TABLE ? {} : selectedColumns;

        const organizationRef = doc(db, "organizations", userDocument.organization);
        const pinboardDocRef = doc(organizationRef, "pinboards", pinboardId);
        const pinRef = doc(pinboardDocRef, "pins", pinId);
        // Fetch the existing pin to retain its chartConfig
        const pinSnap = await getDoc(pinRef);

        if (!pinSnap.exists()) {
          throw new Error("Pin not found!");
        }

        const existingPinData = pinSnap.data() as Pin;
        const existingChartConfig = existingPinData.chartConfig || {};

        const updatePayload = KPIName
          ? {
              chartConfig: {
                ...existingChartConfig, // Retain existing chartConfig
                options: {
                  ...existingChartConfig.options, // Retain existing options
                  KPIName, // Update KPIName
                },
              },
            }
          : {
              selectedColumns: _selectedColumns,
              visualisation,
            };

        // Update the document
        await updateDoc(pinRef, updatePayload);
        console.info("Pin visualisation updated successfully!");
        showAlert({ variant: "success", message: _(msg`Pin visualisation updated successfully!`) });

        await fetchPinboardResultsForBoard();
      } catch (error) {
        console.error("Error updating pin visualisation:", error);
        showAlert({
          variant: "error",
          message: _(msg`Error updating pin visualisation! Please try again later.`),
        });
        throw error;
      }
    }
  );
  /**
   * Updates an individual pin's fields in the local state for the current board.
   *
   * @param pinId - The ID of the pin to update.
   * @param updates - The fields to update in the pin.
   */
  const updatePinFields = (pinId: string, updates: Partial<Pin>) => {
    // Ensure the function only modifies the pins in the currently selected board
    if (currBoardId) {
      setPinboardResults((prevResults) =>
        prevResults.map((pin) =>
          pin.id === pinId
            ? {
                ...pin,
                ...updates,
              }
            : pin
        )
      );
      console.info("Pin updated locally!");
    } else {
      console.error("No current board selected. Cannot update pin.");
    }
  };

  return (
    <PinboardContext.Provider
      value={{
        loading,
        boards,
        setBoards,
        currBoardId,
        setCurrBoardId,
        pinboardResults,
        setPinboardResults,
        addNewBoard,
        deleteBoard,
        updateBoardName,
        fetchPinboardResultsForBoard,
        pinToBoard,
        unpinFromBoard,
        updatePinTitle,
        updatePinVisualization,
        updatePinFields,
        dataLastUpdated,
      }}
    >
      {children}
    </PinboardContext.Provider>
  );
};
