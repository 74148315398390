import { isString } from "lodash";
import { ReactNode, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Loader } from "../canvasApp/components/UI/Loader/Loader";
import { LoadingPage } from "../components/LoadingPage/LoadingPage";
import { useAuth as useAppAuth } from "../context";
import { withSentry } from "../helpers/wrapper";
import { signUpWithFirebase } from "../services/firebase";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

const CanvasSignIn = () => {
  const { _ } = useLingui();
  const urlParams = new URLSearchParams(window.location.search);
  const authApp = useAppAuth();
  const { authenticateWithToken, setAccountId } = authApp;

  const handleCanvasTokenAuth = withSentry(async () => {
    const ref = urlParams.get("ref");
    const aId = urlParams.get("aId");
    if (ref) {
      try {
        // decode the base64 encoded ref to object
        const base64Url = ref;
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        const token = JSON.parse(jsonPayload).token;
        if (token) {
          setAccountId(aId);
          await authenticateWithToken(token);
        }
      } catch (error) {
        console.error("Error during Canvas authentication:", error);
        throw error;
      }
    }
  });

  useEffect(() => {
    handleCanvasTokenAuth();
  }, []);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <Loader message={_(msg`Loading...`)} />
    </div>
  );
};

const ASUGSVSignUp = () => {
  const { _ } = useLingui();
  useEffect(() => {
    const createAccountAndSignIn = async () => {
      try {
        const email = `${uuidv4()}@doowii.io`;
        // eslint-disable-next-line lingui/no-unlocalized-strings
        const password = "ASUgsvPassw0rdf0rDemoUsers04122024";
        await signUpWithFirebase(email, password);
      } catch (error) {
        console.error("Failed to create account:", error);
      }
    };

    createAccountAndSignIn();
  }, []);
  return <Loader message={_(msg`Setting up your account...`)} />;
};

interface ProtectedRouteLayoutProps {
  children: ReactNode;
  isProtected?: boolean;
  isDeveloper?: boolean;
}

export const ProtectedRouteLayout = ({
  children,
  isProtected = true,
  isDeveloper = false,
}: ProtectedRouteLayoutProps) => {
  const currentPath = window.location.pathname;
  const authApp = useAppAuth();
  const { authUser, userDocument, isLoading } = authApp;

  const authMethod = currentPath.startsWith("/canvas-ui") ? "canvas" : "normal";
  const isAuthenticated = !!authUser;
  const isAuthenticating = !authUser && isLoading;
  const isStatus = currentPath.startsWith("/status");

  const userType = userDocument?.role?.startsWith("urn:lti")
    ? "canvas"
    : isString(userDocument?.role)
    ? "normal"
    : undefined;

  const accessNotAllowed = userType === "normal" && authMethod === "canvas";
  return (
    <>
      {!isProtected ? (
        isAuthenticated && !isStatus ? (
          <Navigate to="/" />
        ) : (
          <>{children}</>
        )
      ) : (
        <>
          {authMethod === "canvas" &&
            (!isAuthenticated ? (
              <CanvasSignIn />
            ) : accessNotAllowed ? (
              <Trans>Access Not Allowed</Trans>
            ) : (
              <>{children}</>
            ))}
          {authMethod === "normal" &&
            (isAuthenticated ? (
              <>{children}</>
            ) : isAuthenticating ? (
              <LoadingPage />
            ) : (
              <Navigate to="/sign-in" />
            ))}
        </>
      )}
    </>
  );
};
