import {
  Button,
  Flex,
  IconButton,
  IconSearchLine,
  IconXSolid,
  ScreenReaderContent,
  TextInput,
  Tooltip,
} from "@instructure/ui";
import { useDebounce } from "@uidotdev/usehooks";
import { FC, useEffect, useState } from "react";

import { IconBulletListLine, IconDownloadLine, IconPlayLine } from "@instructure/ui-icons";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { useAlert } from "../../../../context/alert";
import { useAuth } from "../../../../context/auth";
import { useChart } from "../../../../context/chart";
import { usePinboard } from "../../../../context/pinboard";
import { withSentry } from "../../../../helpers/wrapper";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import { Analytics } from "../../../../services/analytics/Analytics";
import { PinView } from "../PinboardTab";
import { EditMode } from "./EditMode/EditMode";
import { SelectMode } from "./SelectMode/SelectMode";

interface SelectionAndSearchProps {
  search: string;
  hasData: boolean;
  setSearch: (search: string) => void;
  setPinView: (view: PinView) => void;
  setCreateModalOpen: (value: boolean) => void;
  setDeleteModalOpen: (value: boolean) => void;
  setIsPresentMode: (value: boolean) => void;
}

export const SelectionAndSearch: FC<SelectionAndSearchProps> = ({
  search,
  hasData,
  setSearch,
  setPinView,
  setCreateModalOpen,
  setDeleteModalOpen,
  setIsPresentMode,
}) => {
  const { _ } = useLingui();
  const { authUser, featureFlags, baseURL } = useAuth();
  const { boards, currBoardId, setCurrBoardId, pinboardResults } = usePinboard();
  const { exportChart } = useChart();
  const { isChatDesktop } = useBreakpoint();
  const { alertPromise } = useAlert();

  const [editMode, setEditMode] = useState(false);
  const [localSearch, setLocalSearch] = useState("");
  const debouncedSearchTerm = useDebounce(localSearch, 300);

  useEffect(() => {
    setSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (search === "") setLocalSearch("");
  }, [search]);

  const handlePinboardSelect = (_: any, { id }: { id: string }) => {
    if (id === "new") {
      setCreateModalOpen(true);

      return;
    }

    setCurrBoardId(id);
    if (!authUser) {
      throw new Error("User not found!");
    }
  };

  const setPinViewWithStorage = (view: PinView) => {
    setPinView(view);
    localStorage.setItem(`pinView-${currBoardId}`, view);
  };

  const currentBoardName = boards.get(currBoardId)?.name;

  const handleDownloadPinboardPDF = withSentry(() => {
    alertPromise({
      promise: () =>
        exportChart({
          pins: pinboardResults,
          type: "PDF",
          baseURL,
          fileName: `${currentBoardName}.pdf`,
          onComplete: () => {
            Analytics.track("Downloaded Pinboard PDF");
          },
        }),
      loading: _(msg`Preparing Pinboard PDF...`),
      success: _(msg`Pinboard PDF prepared successfully`),
      error: _(msg`Pinboard PDF preparation failed`),
    });
  });

  return (
    <div className="mx-[3px] my-6 flex flex-col items-center gap-2 md:flex-row md:gap-9">
      {/* first col  */}
      <div className="flex w-full min-w-80 gap-2 md:w-1/3">
        {editMode ? (
          <EditMode setEditMode={setEditMode} />
        ) : (
          <SelectMode
            boards={boards}
            currentBoardName={currentBoardName}
            currBoardId={currBoardId!}
            handlePinboardSelect={handlePinboardSelect}
            setEditMode={setEditMode}
            setDeleteModalOpen={setDeleteModalOpen}
          />
        )}
      </div>
      {/* second col */}
      <div className="w-full md:w-1/3">
        <TextInput
          renderBeforeInput={IconSearchLine}
          renderLabel={
            <ScreenReaderContent>
              <Trans>Type to search for a Pinboard</Trans>
            </ScreenReaderContent>
          }
          renderAfterInput={
            !!localSearch && (
              <Tooltip renderTip={_(msg`Clear search`)} on={["hover", "focus"]}>
                <IconButton
                  screenReaderLabel={_(msg`Delete tag`)}
                  withBackground={false}
                  withBorder={false}
                  size="small"
                  onClick={() => setLocalSearch("")}
                >
                  <IconXSolid />
                </IconButton>
              </Tooltip>
            )
          }
          disabled={!hasData}
          placeholder={_(msg`Search within ${currentBoardName}`)}
          value={localSearch}
          onChange={(e) => setLocalSearch(e.target.value)}
          id="pinboard-search-input"
        />
      </div>
      {/* third col */}
      <div className="w-full md:w-1/3">
        <Flex gap="small" justifyItems="end">
          <Flex gap="xxx-small">
            <Tooltip renderTip={<Trans>Grid view</Trans>}>
              <IconButton
                margin="xxx-small"
                withBackground={false}
                withBorder={false}
                onClick={() => setPinViewWithStorage("grid")}
                screenReaderLabel={_(msg`Grid view`)}
                size="medium"
              >
                <GridViewOutlinedIcon />
              </IconButton>
            </Tooltip>

            <Tooltip renderTip={<Trans>List view</Trans>}>
              <IconButton
                margin="xxx-small"
                withBackground={false}
                withBorder={false}
                onClick={() => setPinViewWithStorage("list")}
                screenReaderLabel={_(msg`List view`)}
                size="medium"
              >
                <IconBulletListLine />
              </IconButton>
            </Tooltip>
          </Flex>
          {isChatDesktop && (
            <Tooltip renderTip={<Trans>Download Pinboard as PDF</Trans>}>
              <IconButton
                margin="xxx-small"
                onClick={() => handleDownloadPinboardPDF()}
                screenReaderLabel={_(msg`Export to PDF`)}
                size="medium"
              >
                <IconDownloadLine />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip renderTip={<Trans>Present Pinboard</Trans>}>
            <Button
              renderIcon={<IconPlayLine />}
              onClick={() => setIsPresentMode(true)}
              color="primary"
              disabled={!hasData}
            >
              <Trans>Present</Trans>
            </Button>
          </Tooltip>
        </Flex>
      </div>
    </div>
  );
};
