import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Analytics } from "../services/analytics/Analytics";
import { msg } from "@lingui/macro";
import { renderMethologyMarkdown } from "../canvasApp/components/ChatTab/ChatArea/ChatTile/SpeechBubble/BubbleTextArea/TextArea/BotTextArea";
import { measureComponentHeight } from "./MeasureComponent";
import { ChartContextI } from "../context/chart/chart.i";
import { firebaseTimestampToLuxon } from "./time";
import { getRelativeDateAndTime } from "../canvasApp/components/PinboardTab/PinFullScreenView/helpers/time";
import { Pin } from "../context/pinboard/pinboard.i";
import { Result } from "../context/chat/chat.i";
import { FirebaseTimestamp } from "../types/common.i";
import { svg2png } from "./exportPNG";

interface GeneragePDFParams {
  doc?: jsPDF;
  result: Result | Pin;
  chartInstance?: ChartContextI["chartInstances"][0];
  baseURL?: string;
  pageData: any;
  chatSummary: string;
  _: any;
}

const addHyperlinks = (data, doc, tableHeaders, pageData, baseURL) => {
  const x = data.cell.x;
  const y = data.cell.y + 5;

  if (data.cell.section === "body") {
    if (pageData?.pageInfo?.hyperlinkColumns?.courses?.includes(tableHeaders[data.column.index])) {
      doc.setFillColor(data.cell.styles.fillColor);
      doc.rect(x, y - 5, data.cell.width, data.cell.height, "F");
      doc.setTextColor("blue");
      doc.textWithLink(data.cell.raw.toString(), x, y, {
        url: `${baseURL}/courses/${data.cell.raw}`,
      });
    } else if (
      pageData?.pageInfo?.hyperlinkColumns?.gradebook?.includes(tableHeaders[data.column.index])
    ) {
      doc.setFillColor(data.cell.styles.fillColor);
      doc.rect(x, y - 5, data.cell.width, data.cell.height, "F");
      doc.setTextColor("blue");
      doc.textWithLink(data.cell.raw.toString(), x, y, {
        url: `${baseURL}/courses/${data.row.raw[0]}/gradebook`,
      });
    }
  }
};

const addHeaderToPage = (
  doc: jsPDF,
  result: Result | Pin,
  _: any,
  contentWidth: number
): number => {
  doc.setFontSize(18);
  // make title bold
  doc.setFont("helvetica", "bold");
  const splitTitle = doc.splitTextToSize(result?.title, contentWidth);
  doc.text(splitTitle, 10, 10);

  let titleSeparatorStartY = splitTitle.length * 10;

  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.setTextColor("gray");
  if ("created_at" in result && "last_updated" in result) {
    const pinnedOn = getRelativeDate(result.created_at as FirebaseTimestamp);
    const lastUpdated = getRelativeDate(result.last_updated as FirebaseTimestamp);
    doc.text(
      _(msg`Pinned On: ${pinnedOn} • Last Updated On: ${lastUpdated}`),
      10,
      (titleSeparatorStartY += 5)
    );
  }

  titleSeparatorStartY += 10;
  doc.setDrawColor("gray");
  doc.setLineWidth(0.1); // Set the line width to a consistent value
  doc.line(10, titleSeparatorStartY, 200, titleSeparatorStartY);
  doc.setTextColor("black");

  return titleSeparatorStartY; // Return the new y-offset
};

const getRelativeDate = (timestamp: FirebaseTimestamp) => {
  const luxonTimestamp = firebaseTimestampToLuxon(timestamp);
  const relativeDate = getRelativeDateAndTime(luxonTimestamp);

  return relativeDate;
};

export const generatePDF = async ({
  doc = new jsPDF(),
  result,
  chartInstance,
  baseURL,
  pageData,
  chatSummary,
  _,
}: GeneragePDFParams) => {
  const contentWidth = 190;
  try {
    let titleSeparatorStartY = addHeaderToPage(doc, result, _, contentWidth);
    const chatSummaryComponent = renderMethologyMarkdown(chatSummary) as JSX.Element;
    const { height: chatSummaryHeightPixels, tempDiv: chatSummaryTempDiv } =
      await measureComponentHeight(chatSummaryComponent, 700);

    const tableHeaders = pageData?.pageInfo.headers;

    // set y to be top of the last page
    const lastPageY = (doc.getNumberOfPages() - 1) * doc.internal.pageSize.height;
    await doc.html(chatSummaryTempDiv, {
      x: 10,
      y: lastPageY + titleSeparatorStartY + 5,
      width: contentWidth,
      windowWidth: 700,
      autoPaging: "text",
    });

    // convert pixel height to mm
    const chatSummaryHeight = chatSummaryHeightPixels * 0.26458333 ?? 0;
    let contentEndY = chatSummaryHeight + titleSeparatorStartY + 20;

    // if there are children, it means the chart builder tab is open
    if (chartInstance) {
      // remove the chart title from highcharts chartOptions
      const chartSVG = chartInstance.chart.getSVG({ title: { text: null } });
      const chartPNG = await svg2png(chartSVG, 800 * 1.5, 600 * 1.5);

      // Calculate aspect ratio
      const aspectRatio = 1.333;
      // Define maximum width and height for the image
      const maxWidth = contentWidth;
      const maxHeight = 125;
      const minHeight = 90;

      const availableHeight = 297 - contentEndY;

      // Calculate dimensions that fit within maxWidth and maxHeight while maintaining aspect ratio
      let width = maxWidth;

      // Check if the image fits on the current page (A4 paper height === 297mm)
      if (availableHeight < minHeight) {
        doc.addPage();
        titleSeparatorStartY = addHeaderToPage(doc, result, _, contentWidth);
        doc.addImage(chartPNG, "PNG", 10, titleSeparatorStartY + 5, maxWidth, maxHeight);
      } else {
        const height = Math.min(availableHeight, maxHeight);
        // using availableHeight calculate weight respecting aspect ratio
        width = height * aspectRatio;
        // calculate x offset using new width
        const xOffset = (maxWidth - width) / 2;

        doc.addImage(chartPNG, "PNG", 10 + xOffset, contentEndY, width, height);
      }
    } else if (pageData && tableHeaders) {
      autoTable(doc, {
        startY: contentEndY,
        margin: { top: titleSeparatorStartY + 10 },
        head: [tableHeaders],
        body: pageData.rows.map((row) => tableHeaders.map((header) => row[header])),
        didDrawCell: (data) => addHyperlinks(data, doc, tableHeaders, pageData, baseURL),
        willDrawPage: () => {
          addHeaderToPage(doc, result, _, contentWidth);
        },
      });

      const posY = (doc as any).lastAutoTable.finalY;
      doc.setFontSize(10);
      doc.text(
        _(msg`${pageData?.rows?.length} rows of ${pageData?.pageInfo?.totalRowCount}`),
        contentWidth,
        posY + 10,
        { align: "right" }
      );
    }
    return doc;
  } catch (error) {
    throw error;
  } finally {
    Analytics.track("Download PDF");
  }
};
