/* eslint-disable lingui/no-unlocalized-strings */
import { ReactNode } from "react";
import { createBrowserRouter } from "react-router-dom";
import CanvasApp from "../canvasApp";
import { ProtectedRouteLayout } from "../layouts/protected-route-layout";
import { ErrorPage } from "./error-page";
import { SignIn } from "./sign-in";
import Status from "../status/Status";

interface Route {
  path: string;
  element: ReactNode;
  isProtected?: boolean;
  isDeveloper?: boolean;
}

const publicRoutes: Route[] = [
  {
    path: "/sign-in",
    element: <SignIn />,
    isProtected: false,
  },
  {
    path: "/health/ping",
    element: <div>Pong</div>,
    isProtected: false,
  },
  {
    path: "/status",
    element: <Status />,
    isProtected: false,
  },
];

const privateRoutes: Route[] = [
  {
    path: "/canvas-ui",
    element: <CanvasApp />,
    isProtected: true,
  },
];

const routes = [...publicRoutes, ...privateRoutes];

export const router = createBrowserRouter(
  routes.map(({ path, element, isProtected = true, isDeveloper = false }) => ({
    path,
    element: (
      <ProtectedRouteLayout isProtected={isProtected} isDeveloper={isDeveloper}>
        {element}
      </ProtectedRouteLayout>
    ),
    errorElement: <ErrorPage />,
  }))
);
