import { DEFAULT_QUERY_OPTIONS, TABLE } from "../../../Constants/constants";
import { DataTable } from "../DataTable/DataTable";
import { ChartRenderer } from "../ChartRenderer/ChartRenderer";
import { allCanvasCharts, PlottableChartTypes } from "../ChartBuilder/ChartBuilder";
import { Pin } from "../../../context/pinboard/pinboard.i";
import { useEffect, useState } from "react";
import { useChatData } from "../../../context/chat";
import { useQuery } from "../../../hooks/useQuery";

interface PinboardChartContentProps {
  pinResult: Pin;
  width: number;
  height: number;
}

export const PinboardChartContent = ({ pinResult, width, height }: PinboardChartContentProps) => {
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const { dataMap } = useChatData();
  const { loading } = useQuery(
    pinResult,
    { paginationModel: DEFAULT_QUERY_OPTIONS },
    shouldFetchData
  );

  useEffect(() => {
    if (pinResult?.id && !dataMap[pinResult.id]) {
      setShouldFetchData(true);
    }
    if (pinResult?.id && dataMap[pinResult.id]) {
      setShouldFetchData(false);
    }
  }, [pinResult, dataMap]);

  const chartProps = {
    // TODO: Remove this once we check chart_config is not used anymore
    chartConfig: pinResult.chartConfig || (pinResult as Pin).chart_config,
    selectedColumns: pinResult.selectedColumns,
    docId: pinResult.id,
    title: pinResult.title,
    loading: loading,
    error: false,
    chartType: pinResult.visualisation as unknown as PlottableChartTypes,
    sql: pinResult.sql,
    parentDocId: pinResult.parentDocId,
    parentDocType: pinResult.parentDocType,
  };

  return (
    <>
      {pinResult.visualisation === TABLE && height ? (
        <DataTable result={pinResult} height={height} selectable={false} />
      ) : null}
      {allCanvasCharts.includes(chartProps.chartType as PlottableChartTypes) && (
        <ChartRenderer chartProps={chartProps} />
      )}
    </>
  );
};
