// components/PinFullScreenView.tsx
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Button, CloseButton, Heading, Tooltip, TruncateText, Text } from "@instructure/ui";
import { Trans, msg } from "@lingui/macro";
import { withSentry } from "../../../../../helpers/wrapper";
import { SINGLE_METRIC, TABLE } from "../../../../../Constants/constants";
import { useChatData } from "../../../../../context/chat";
import { usePinboard } from "../../../../../context/pinboard";
import { firebaseTimestampToLuxon } from "../../../../../helpers/time";
import { ChartType } from "../../../../types";
import { DataVisualizationTabs } from "../../../DataVisualizationTabs/DataVisualizationTabs";
import { useLingui } from "@lingui/react";
import { getRelativeDateAndTime } from "../helpers/time";
import { FullScreenPinProps, PageData } from "../types";
import { useAuth } from "../../../../../context";
import { PinViewContainer } from "./PinViewContainer";
import { ActionsOptionsContainer } from "./ActionsOptionsContainer";
import { Pin, VisualizationTypesEnum } from "../../../../../context/pinboard/pinboard.i";
import { renderMethologyMarkdown } from "../../../ChatTab/ChatArea/ChatTile/SpeechBubble/BubbleTextArea/TextArea/BotTextArea";
import { useChart } from "../../../../../context/chart";
import { downloadCSV } from "../../../../../helpers/downloadCSV";
import { ParentDocTypeEnum } from "../../../../../api/retriever.i";
import { useAlert } from "../../../../../context/alert";
import { Analytics } from "../../../../../services/analytics/Analytics";
import { ViewEnum } from "../../../../../context/ui/ui.i";

/**
 * Component for displaying a full-screen view of a pinned chart.
 */
export const PinFullScreenView: FC<FullScreenPinProps> = ({
  open,
  setOpen,
  chart,
  setDeleteChartModalOpen,
}) => {
  const { baseURL } = useAuth();
  const { _ } = useLingui();
  const { alertPromise } = useAlert();
  const [pageData, setPageData] = useState<PageData>();
  const [pinChartType, setPinChartType] = useState<ChartType>(TABLE as ChartType);
  const [pinSelectedColumns, setPinSelectedColumns] = useState<{ [key: string]: any }>({});
  const [chatSummary, setChatSummary] = useState<string>(_(msg`Loading...`));
  const [isTitleTruncated, setIsTitleTruncated] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { chartInstances, exportChart } = useChart();
  const [originalKPIName, setOriginalKPIName] = useState<string>("");
  const { currBoardId, updatePinVisualization, dataLastUpdated, updatePinFields } = usePinboard();
  const { fetchSummaryFromPin } = useChatData();

  const whiteboardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chartConfig = chart.chartConfig || chart.chart_config;
    if (chart?.visualisation === SINGLE_METRIC) {
      setOriginalKPIName(chartConfig?.options?.KPIName);
    }
  }, [open]);

  useEffect(() => {
    setPinSelectedColumns(chart?.selectedColumns ?? {});
  }, [chart]);

  useEffect(() => {
    const fetchData = withSentry(async () => {
      const summary = await fetchSummaryFromPin(chart);
      setChatSummary(summary as unknown as string);
    });
    if (open && chart) {
      fetchData();
    }
  }, [open, chart]);

  useEffect(() => {
    return () => {
      setPinChartType(TABLE as ChartType);
      setPinSelectedColumns({});
    };
  }, [open]);

  const isDirty = useMemo(() => {
    const selectedColumnsSet = new Set(Object.keys(pinSelectedColumns));
    const chartSelectedColumnsSet = new Set(Object.keys(chart?.selectedColumns ?? {}));

    const columnsChanged =
      selectedColumnsSet.size !== chartSelectedColumnsSet.size ||
      ![...selectedColumnsSet].every((col) => chartSelectedColumnsSet.has(col));

    if (columnsChanged) {
      if (chart?.visualisation === TABLE && pinChartType === TABLE) return false;
      return true;
    }

    if ((chart?.visualisation as unknown as ChartType) !== pinChartType) return true;

    const chartConfig = chart?.chartConfig || chart?.chart_config;
    if (originalKPIName !== chartConfig?.options?.KPIName) return true;

    return false;
  }, [chart?.visualisation, pinChartType, pinSelectedColumns, originalKPIName]);

  const updatePin = async () => {
    if (!chart) return;
    setUpdating(true);

    await updatePinVisualization(
      currBoardId,
      chart.id,
      pinSelectedColumns,
      pinChartType as unknown as VisualizationTypesEnum,
      pinChartType === SINGLE_METRIC ? chart?.chartConfig?.options?.KPIName : null
    );

    setUpdating(false);
    setOpen(false);
  };

  const handleDownloadPNG = withSentry(() => {
    alertPromise({
      promise: () =>
        exportChart({
          pins: [chart as Pin],
          type: "PNG",
          pinId: chart?.id,
          onComplete: () => {
            Analytics.track("Downloaded Pin PNG");
          },
        }),
      loading: _(msg`Preparing PNG...`),
      success: _(msg`PNG prepared successfully`),
      error: _(msg`PNG preparation failed`),
    });
  });

  const handleDownloadCSV = withSentry(() => {
    alertPromise({
      promise: () =>
        downloadCSV({
          doc_id: chart?.id,
          parent_doc_id: currBoardId,
          parent_doc_type: ParentDocTypeEnum.PINBOARD,
          filename: `${chart?.title}.csv`,
        }),
      loading: _(msg`Preparing CSV...`),
      success: _(msg`CSV prepared successfully`),
      error: _(msg`CSV preparation failed`),
    });
  });

  const handleDownloadPDF = withSentry(() => {
    alertPromise({
      promise: () =>
        exportChart({
          pins: [chart as Pin],
          baseURL,
          fileName: `${chart?.title}.pdf`,
          type: "PDF",
          onComplete: () => {
            Analytics.track("Downloaded Pin PDF");
          },
        }),
      loading: _(msg`Preparing PDF...`),
      success: _(msg`PDF prepared successfully`),
      error: _(msg`PDF preparation failed`),
    });
  });

  const [pinnedOn, lastUpdated] = useMemo(() => {
    const createdAtLuxon = firebaseTimestampToLuxon(chart?.created_at);
    const createdAtRelative = getRelativeDateAndTime(createdAtLuxon);

    const updatedAtLuxon = firebaseTimestampToLuxon(dataLastUpdated ?? chart?.last_updated);
    const updatedAtRelative = getRelativeDateAndTime(updatedAtLuxon);

    return [createdAtRelative, updatedAtRelative];
  }, [chart?.created_at, chart?.last_updated, dataLastUpdated]);

  const renderHeader = () => (
    <Heading>
      <div className="flex flex-col gap-2">
        <TruncateText maxLines={3} onUpdate={(isTruncated) => setIsTitleTruncated(isTruncated)}>
          {chart?.title}
        </TruncateText>
        <div className="flex flex-col gap-1">
          <Text size="small" color="secondary">
            <Trans>Pinned On: {pinnedOn}</Trans>
          </Text>
          <Text size="small" color="secondary">
            <Trans>Last Updated On: {lastUpdated}</Trans>
          </Text>
        </div>
      </div>
    </Heading>
  );

  const handleClose = () => {
    updatePinFields(chart?.id, {
      chartConfig: {
        ...chart.chartConfig,
        options: { ...chart?.chartConfig?.options, KPIName: originalKPIName },
      },
    });
    setOpen(false);
  };
  // TODO: DRY this up with PinboardPresent.tsx
  return (
    <PinViewContainer isOpen={open} handleClose={handleClose} size="auto">
      {/* Header */}
      <div id="full-screen-pin">
        <div className="full-screen-pin-remove">
          <CloseButton
            placement="end"
            offset="small"
            size="medium"
            screenReaderLabel={_(msg`Close`)}
            onClick={handleClose}
          />
        </div>
        {isTitleTruncated ? (
          <Tooltip
            renderTip={chart?.title}
            constrain="parent"
            mountNode={() => document.getElementById("modal-container")}
          >
            {renderHeader()}
          </Tooltip>
        ) : (
          renderHeader()
        )}
      </div>
      {/* Body */}
      <div>
        {/* Content */}
        <div className="flex flex-col justify-between ">
          <div id="chat-summary">{renderMethologyMarkdown(chatSummary)}</div>
          <div className="flex flex-col items-start justify-between gap-3 chat-desktop:items-end">
            <ActionsOptionsContainer
              pinResult={chart}
              handleDownloadCSV={handleDownloadCSV}
              handleDownloadPDF={handleDownloadPDF}
              handleDownloadPNG={handleDownloadPNG}
              setDeleteChartModalOpen={setDeleteChartModalOpen}
              variant="fullscreen"
            />
          </div>
        </div>

        <div
          ref={whiteboardRef}
          style={{ minHeight: "45vh", height: "55vh" }}
          className="flex flex-1 flex-col"
        >
          <DataVisualizationTabs
            result={chart}
            chartType={pinChartType}
            setChartType={setPinChartType}
            setPageData={setPageData}
            didCreateChart={(chartProps) => {
              setPinChartType(chartProps.chartType);
              setPinSelectedColumns(chartProps.selectedColumns);
            }}
            view={ViewEnum.PINBOARD_VIEW}
          />
        </div>
      </div>
      {/* Footer */}
      <div className="full-screen-pin-remove flex items-center justify-end gap-2">
        <div className="mr-3">
          <Button color="primary" disabled={!isDirty || updating} onClick={updatePin}>
            <Trans>Save</Trans>
          </Button>
        </div>
        <Button onClick={handleClose}>
          <Trans>Close</Trans>
        </Button>
      </div>
    </PinViewContainer>
  );
};
