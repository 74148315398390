import { arrayMove } from "@dnd-kit/sortable";
import {
  IconMoveDownBottomLine,
  IconMoveDownLine,
  IconMoveUpLine,
  IconMoveUpTopLine,
  Menu,
} from "@instructure/ui";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useEffect, useRef } from "react";
import { useAlert } from "../../../../context/alert";
import { usePinboard } from "../../../../context/pinboard";
import { Pin } from "../../../../context/pinboard/pinboard.i";
import { savePinOrders } from "../../../../services/webserver/pinboards";

interface MovePopoverProps {
  pinId: string;
  setOpenMoveMenuId: (value: string | null) => void;
  filteredPinboardResults?: Pin[];
  setFilteredPinboardResults?: React.Dispatch<React.SetStateAction<Pin[]>>;
  setPinboardResults: React.Dispatch<React.SetStateAction<Pin[]>>;
}

const MovePopover = ({
  pinId,
  setOpenMoveMenuId,
  filteredPinboardResults,
  setFilteredPinboardResults,
  setPinboardResults,
}: MovePopoverProps) => {
  const { currBoardId } = usePinboard();
  const { showAlert } = useAlert();
  const { _ } = useLingui();
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        setOpenMoveMenuId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenMoveMenuId]);

  const isTop = filteredPinboardResults.findIndex((r) => r.id === pinId) === 0;
  const isBottom =
    filteredPinboardResults.findIndex((r) => r.id === pinId) === filteredPinboardResults.length - 1;

  const movePin = (pinId, newIndex) => {
    setOpenMoveMenuId(null);

    setFilteredPinboardResults((results) => {
      const oldIndex = results.findIndex((r) => r.id === pinId);
      if (oldIndex === -1 || newIndex < 0 || newIndex >= results.length) return results;

      const newArray = arrayMove(results, oldIndex, newIndex);

      savePinOrders(
        currBoardId,
        newArray.map((r) => r.id)
      ).catch((error) => {
        console.error("Error saving pin order:", error);

        showAlert({
          variant: "error",
          message: _(msg`Failed to save the pin order. Please try again later.`),
        });
      });

      return newArray;
    });
    setPinboardResults((results: Pin[]): Pin[] => {
      const oldIndex = results.findIndex((r) => r.id === pinId);
      if (oldIndex === -1 || newIndex < 0 || newIndex >= results.length) return results;

      return arrayMove(results, oldIndex, newIndex);
    });
  };

  const handleMoveUp = () => {
    const oldIndex = filteredPinboardResults.findIndex((r) => r.id === pinId);
    movePin(pinId, oldIndex - 1);
  };

  const handleMoveDown = () => {
    const oldIndex = filteredPinboardResults.findIndex((r) => r.id === pinId);
    movePin(pinId, oldIndex + 1);
  };

  const handleMoveToTop = () => {
    movePin(pinId, 0);
  };

  const handleMoveToBottom = () => {
    movePin(pinId, filteredPinboardResults.length - 1);
  };

  return (
    <div
      ref={popoverRef}
      className="absolute left-2 top-10 z-50 border border-gray-200 bg-white shadow-lg"
    >
      <Menu label={_("Move chart")}>
        {!isTop && (
          <Menu.Item
            onClick={() => {
              handleMoveToTop();
            }}
          >
            <span className="flex items-center  gap-2">
              <IconMoveUpTopLine />
              <Trans>Move to Top</Trans>
            </span>
          </Menu.Item>
        )}

        {!isTop && (
          <Menu.Item
            onClick={() => {
              handleMoveUp();
            }}
          >
            <span className="flex items-center  gap-2">
              <IconMoveUpLine />
              <Trans>Move up</Trans>
            </span>
          </Menu.Item>
        )}
        {!isBottom && (
          <Menu.Item
            onClick={() => {
              handleMoveDown();
            }}
          >
            <span className="flex items-center  gap-2">
              <IconMoveDownLine />
              <Trans>Move down</Trans>
            </span>
          </Menu.Item>
        )}
        {!isBottom && (
          <Menu.Item
            onClick={() => {
              handleMoveToBottom();
            }}
          >
            <span className="flex items-center  gap-2">
              <IconMoveDownBottomLine />
              <Trans>Move to bottom</Trans>
            </span>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export { MovePopover };
